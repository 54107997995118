.tags-with-expander {
  $shadow-small: 1px 1px 3px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  color: $font-color-7;
  position: relative;

  &__expander {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: $black;
    line-height: normal;
    border-radius: 20px;
  }

  &__expander-button {
    background-color: transparent;
    border: none;
    margin-inline: 0.5rem;
    padding: 0;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid $font-color-2;
    font-size: 0.85rem;
    padding: 0 0.25rem;
  }

  &__additional-tags {
    display: none;
    flex-direction: column;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    box-shadow: $shadow-small;
    width: max-content;
    position: absolute;
    left: 0;
    top: calc(100% + 1rem);
    background-color: $font-color-2;
    font-size: 0.85rem;
    list-style: none;
    z-index: 99;

    &--visible {
      display: flex;
    }
  }
}
