.biography-detail {
  &__highlight {
    display: flex;
    flex-direction: column;

    @media (min-width: $md-min) {
      flex-direction: row;
    }

    img {
      width: 100%;

      @media (min-width: $md-min) {
        width: 21.5rem;
        max-width: 100%;
      }
    }
  }

  &__caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;

    @media (min-width: $md-min) {
      padding: 2rem;
      margin-top: 0;
    }
  }

  &__biography {
    margin-top: 2rem;
  }
}
