.section-block {
  padding: 0;
  position: relative;
  width: 100%;
  max-width: $content__max-width;
  margin: 0 auto;
  & .section-block__title {
    & .heading-title {
      text-align: center;
    }
  }

  &__background-image {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    display: flex;
    position: relative;
    padding: 1.5rem;

    &:is(.block-padding-off):not(.separator-vertical) {
      padding-bottom: 1.5rem;
      & .section-block__separator & {
        padding-bottom: 0;
      }
    }
    &:is(.separator-vertical) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    @media screen and (min-width: $md-min) {
      // padding: 2.5rem;
      width: 100%;
      &.inline-padding-off {
        padding-inline: 1.5rem;
      }
      &.block-padding-off {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
      &.separator-vertical {
        border-right: 1.25px solid $font-color-2;
      }
      &:is(.block-padding-off):not(.separator-vertical) {
        padding-bottom: 2.5rem;
        & .section-block__separator {
          .section-block__container {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: $xl-min) {
    &__container {
      padding: 5rem 10rem;
      width: 100%;
      &.inline-padding-off {
        padding-inline: 0;
      }
      &.block-padding-off {
        padding-bottom: 0;
      }
      &:is(.block-padding-off):not(.separator-vertical) {
        padding-bottom: 5rem;
        & .section-block__separator {
          .section-block__container {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  &__left-aligment {
    text-align: left;
  }

  &__eyebrow {
    font-family: $font-family-2;
    font-size: 0.875rem;
    font-weight: 800;
    color: $background-color-10;
    margin: 0 0 0.75rem 0;
    display: block;

    @media only screen and (min-width: $lg-min) {
      font-size: 1.125rem;
      font-weight: 800;
      margin: 0 0 1.625rem 0;
    }
  }

  &__title {
    text-align: center;
  }

  &__description {
    font-family: $font-family-2;
    font-size: 1.25rem;
    font-weight: normal;
    margin: 0 0 2rem 0;
    text-align: left;
    & > * {
      font-family: $font-family-2;
      font-size: inherit;
      font-weight: normal;
      margin: 0 0 2rem 0;
      text-align: inherit;
      background-color: inherit;
      color: inherit;
    }
    @media only screen and (min-width: $lg-min) {
      font-size: 1.5rem;
      margin: 0 0 3rem 0;
    }
  }

  &__separator {
    &-horizontal {
      width: 100%;
      margin: 3rem 0 0;
      border: none;
      border-top: 1.25px solid $font-color-2;
      @media only screen and (min-width: $lg-min) {
        margin: 5rem 0 0;
      }
    }
    &-vertical {
      width: 2px;
      height: 100%;
      background-color: $font-color-2;
    }
  }

  &__main-content-area {
    margin-bottom: 2rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 3.5rem;

      & > .row {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__links-content-area {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 3.5rem;
    align-items: center;
    @media screen and (min-width: $lg-min) {
      gap: 2.5rem;
      flex-direction: row;
      &.cta-justify-start {
        justify-content: flex-start;
      }
      &.cta-justify-center {
        justify-content: center;
      }
    }
  }

  &__no-padding {
    padding: 0;

    @media only screen and (min-width: $lg-min) {
      padding: 0;
    }
  }
}
