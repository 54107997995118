.cw-table {
  width: 100%;
  max-width: 100rem;
  max-height: 50vh;
  margin: 0 auto;
  overflow-x: auto;
  position: relative;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  tr:has(th) {
    position: sticky;
    top: 0;
    th {
      border-bottom: none;
    }
  }
  th {
    background-color: $font-color-3;
    color: $font-color-16;
  }
  th,
  td {
    border-bottom: 2px solid $font-color-2;
    padding: 1.5rem 1rem;
    min-width: 12.5em;
    max-width: 12.5em;
    word-wrap: break-word;
  }
}
