.video {
  width: 100%;
  height: auto;
  &-mb {
    padding-bottom: 1.5rem;
    @media (min-width: $lg-min) {
      padding-bottom: 2rem;
    }
  }
  iframe {
    border: none;
  }
}
