.card {
  position: relative;
  $shadow-small: 1px 1px 3px 0 rgba(0, 0, 0, 0.16);
  .image-wrapper,
  .icon-wrapper {
    img {
      width: 100%;
      display: inline-block;
      @media (min-width: $lg-min) {
        max-width: 100%;
      }
    }
  }
  & .button-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    @media (min-width: $lg-min) {
      width: max-content;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .tags-wrapper {
    margin-bottom: 1rem;
    position: relative;
  }
  & .flag {
    background: transparent;
    &:has(.flagtext) {
      height: 1.25rem;
      margin-bottom: 1.25rem;
    }
    @media (min-width: $lg-min) {
      height: 1.25rem;
      margin-bottom: 1.25rem;
    }
    & .eyebrow {
      display: inline-block;
      font-size: 1rem;
      font-weight: 500;      
      margin-right: 0.313rem;
    }
    & .flagtext {
      
    }

    p {
      display: inline-block;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0;
    padding-bottom: 1rem;
  }

  &.separator-horizontal {
    border-bottom: 1.25px solid;
  }
  &:is(.separator-horizontal) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .heading {
    .heading-title {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }
  &.callout {
    display: flex;
    flex-direction: column;
    height: auto;
    text-decoration: none;
    .container {
      flex-direction: column;
      .image-wrapper {
        margin-bottom: 1rem;
        width: 50px;
        img {
          width: 100%;
        }
      }
    }

    & .heading {
      & .heading-title {
        font-size: 1.25rem;
        margin: 0 0 1rem 0;
        display: block;
        @media screen and (min-width: $lg-min) {
          font-size: 1.5rem;
        }
      }
    }

    & .description {
      margin-bottom: 1.5rem;
      width: 90%;
      p {
        font-size: 1rem;
        font-weight: 300;
      }

      .icon-wrapper {
        figure {
          margin-bottom: 0;
        }
      }

      img {
        width: 3.125rem;
        margin-bottom: 1.5625rem;
      }

      a {
        margin-top: 1.25rem;
        text-transform: uppercase;
        font-weight: 700;
        color: white;

        &:hover {
          text-decoration: underline;
  
        }

        svg {
          width: auto;
          height: 1.25rem;
          color: white;
        }
      }
    }

    & .button-wrapper {
      .call-to-action {
        @media (max-width: $lg-min) {
          width: max-content;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }

  &.promo {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    .container {
      flex-direction: column;
      justify-content: center;
      .image-wrapper {
        width: 80%;
        margin-bottom: 2rem;
        img {
          width: 75%;
        }
        @media screen and (min-width: $md-min) {
          width: 50%;
        }
      }
    }
    & .heading {
      & .heading-title {
        font-size: 2rem;
        font-weight: 700;
        margin: 0 0 1rem 0;
        display: block;
        @media screen and (min-width: $md-min) {
          font-size: 3rem;
        }
      }
    }

    .icon-wrapper {
      img {
        width: 7.8125rem;
        margin-bottom: 0.625rem;
      }
    }

    .description {
      margin-bottom: 2rem;
      p {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.63rem;
        @media screen and (min-width: $md-min) {
          font-size: 1rem;
        }
      }
    }

    .button-wrapper {
      flex-wrap: wrap;

      .call-to-action {
        margin: 0 2rem 1.5rem 0;

        @media (max-width: $sm-max) {
          width: 100%;
          margin: 0 0 1.5rem 0;
          justify-content: center;
        }
      }

      .button {
        font-size: 0.8rem;
        font-weight: 700;
        padding: 0.75rem 1.5rem;
      }
    }
  }

  &.spotlight {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    .container {
      flex-direction: column;
      .column-one {
        flex-basis: 60%;
      }
    }
    .image-wrapper {
      padding: 0;
      margin-bottom: 0.9375rem;

      a {
        margin-top: 0;
      }
    }

    & .heading {
      & .heading-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0 0 1rem 0;
        display: block;
        @media (min-width: $lg-min) {
          font-size: 2rem;
        }
      }
    }

    .call-to-action {
      margin-top: 1.25rem;
      text-transform: uppercase;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
        color: white;
      }
    }

    .description {
      p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.3rem;
      }
    }
  }

  &.numbered-tile {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    text-decoration: none;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    padding: 3.75rem 2rem 2rem;
    @media screen and (min-width: $sm-max) {
      padding: 5rem 3.5rem;
      margin-left: 0;
    }

    .heading-title {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 0 1rem 0;
    }

    & .number-tile {
      position: absolute;
      top: -1rem;
      left: -1rem;
      height: 4.875rem;
      width: 3.75rem;

      p {
        font-size: 4rem;
        line-height: 5rem;
        text-align: center;
        color: white;
        font-weight: 300;
      }
    }

    .button-wrapper {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      justify-content: center;

      @media (min-width: $lg-min) {
        width: max-content;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
      }
    }

    .button {
      margin: 0 1.5rem 1.5rem 0;
    }

    .description {
      margin-bottom: 2.5rem;

      & > * {
        font-size: 1.25rem;
        font-weight: 300;
      }
    }
  }

  &.stats {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    text-decoration: none;
    .button-wrapper {
      align-items: center;
      @media (min-width: $lg-min) {
        justify-content: center;
        width: 100%;
      }
    }
    .container {
      margin: 0 auto;
    }

    .stats__heading {
      width: 100%;

      .heading {
        position: relative;
        width: fit-content;
        margin: 0 auto;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.625rem;
          margin-top: 2.25rem;
          justify-content: center;
        }

        .heading-title {
          position: relative;
          display: block;
          font-size: 5.5rem;
          font-weight: 700;
          line-height: 1rem;
          margin: 2rem 0 1rem 0;
          text-align: center;
        }
      }
    }

    .description,
    .description > * {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.3rem;
      text-align: center;
      margin-top: 3rem;
    }
  }

  &.center-aligned {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    .button-wrapper {
      align-items: center;
      @media (min-width: $lg-min) {
        justify-content: center;
        width: 100%;
      }
    }
    .container {
      justify-content: center;
      flex-direction: column;
    }
    .image-wrapper {
      img {
        width: auto;
        max-width: 100%;
      }
    }

    .heading-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 1rem 0;
      @media (min-width: $lg-min) {
        font-size: 2rem;
      }
    }

    .description {
      & > *,
      p {
        font-size: 0.8rem;
        line-height: 1.3rem;
        text-align: center;
      }
    }

    .call-to-action {
      margin-top: 0.5rem;

      a {
        text-transform: uppercase;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
          color: white;
        }
      }
    }
  }

  &.stacked {
    display: flex;
    flex-direction: column;
    height: auto;
    text-decoration: none;
    & .flag {
      margin-bottom: 1rem;
    }

    & .container {
      padding: 0;
      margin: 0;
      flex-direction: column;
      .column-one {
        margin-bottom: 1rem;
      }
      & .subheading {
        margin-bottom: 1rem;
        & > * {
          margin: 0 0 1rem 0;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: normal;
        }
      }

      & .heading {
        & .heading-title {
          font-size: 1.25rem;
          font-weight: normal;
          line-height: 1.67rem;
          margin-bottom: 1rem;
          display: block;
          @media screen and (min-width: $lg-min) {
            font-size: 1.5rem;
          }
        }
      }

      & .description {
        margin-bottom: 1.5rem;
      }

      & .button-wrapper {
        justify-content: center;
      }
    }
  }

  &.adjacent {
    text-decoration: none;

    .container {
      flex-direction: row;
      padding: 0;
      @media (max-width: $sm-max) {
        display: block;
      }

      & .column-one {
        display: flex;
        flex-direction: column;
        height: auto;
        text-decoration: none;
        flex: 1 1 100%;
        @media (max-width: $sm-max) {
          width: 100%;
          display: block;
          max-width: 100%;
          margin-bottom: 1.5rem;
        }
      }
      .image-wrapper,
      .icon-wrapper {
        height: auto;
        @media (max-width: $sm-max) {
          width: 100%;
        }
      }

      .column-two {
        display: flex;
        flex-direction: column;
        margin-left: 1.5rem;
        flex: 1 1 100%;
        padding-right: 1rem;
        .tag-wrapper {
          margin: 0 0 1rem;
          .subheading {
            & > * {
              text-decoration: none;
              font-size: 1rem;
              font-weight: 500;
            }
          }
        }
        @media (max-width: $sm-max) {
          width: 100%;
          display: block;
          max-width: 100%;
          margin-left: 0;
          .tag-wrapper {
            margin: 0 0 1.5rem;
          }
        }

        .heading {
          .heading-title {
            display: inline-block;
            font-size: 1.25rem;
            line-height: normal;
            margin-bottom: 1rem;
            @media (min-width: $lg-min) {
              font-size: 1.5rem;
            }
          }
        }      
      }
    }
  }
  &.text-only {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    text-decoration: none;
    
    @media (max-width: $sm-max) {
      border-bottom: 0.0625rem solid;
    }

    .container {
      padding: 0;
      margin: 0;
      .subheading {
        & > * {
          margin: 0 0 1rem 0;
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .heading {
        .heading-title {
          font-size: 1.25rem;
          line-height: 1.42;
          @media (min-width: $lg-min) {
            font-size: 1.5rem;
          }
        }
      }     
    }
  }

  &.featured {
    text-decoration: none;

    .container {
      padding: 0;
      padding-bottom: 1rem;
      gap: 1.5rem;
      @media (max-width: $lg-max) {
        flex-direction: column;
      }
      .column-one {
        display: flex;
        flex-direction: column;
        text-decoration: none;
      }

      .column-two {
        display: flex;
        flex-direction: column;
        @media (max-width: $lg-max) {
          display: block;
          width: 100%;
          margin-left: 0;
          margin-top: 2rem;
        }

        .subheading {
          & > * {
            margin: 0 0 1rem 0;
            font-size: 1rem;
            font-weight: 500;            
          }
        }

        .heading {
          .heading-title {
            font-size: 1.5rem;
            line-height: normal;            
            @media (min-width: $lg-min) {
              font-size: 1.875rem;
            }
          }
        }

        .description {
          font-size: 1rem;
          line-height: 1.63;
          margin-bottom: 0;
        }
      }
    }
  }
  &.callout-small {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 0;
    .container {
      flex-direction: column;
    }

    .heading {
      .heading-title {
        font-size: 1.25rem;
        margin: 0 0 1rem 0;
        @media screen and (min-width: $md-min) {
          font-size: 1.5rem;
        }
      }
    }

    .image-wrapper,
    .icon-wrapper {
      margin-bottom: 1.125rem;

      img {
        max-width: 3.125rem;
      }
    }

    .description {
      & > * {
        font-size: 1rem;
        font-weight: 300;
        color: inherit;
      }
    }
  }
  &.grey-asset {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 2.1875rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

    @media screen and (min-width: $md-min) {
      height: 100%;
      padding: 2.5rem;
    }

    .image-wrapper {
      margin-bottom: 1rem;

      img {
        max-width: 48px;
        @media screen and (min-width: $sm-min) {
          font-size: 30px;
        }
      }
    }

    .heading {
      .heading-title {
        font-size: 1.5rem;
        color: inherit;
        margin: 0 0 1rem 0;
      }
    }

    .description {
      & > * {
        font-size: 1rem;
        font-weight: 400;
        color: inherit;
      }
    }
  }
  &.card-wide {
    .container {
      display: flex;
      flex-direction: column;
    }
    .column-two {
      padding: 2rem 0;
    }
    .heading {
      .heading-title {
        font-weight: 600;
        font-size: 1.25rem;
      }
    }
    .description {
      ul {
        padding: 0;
        padding-bottom: 0.5rem;
        li {
          font-weight: 300;
          margin: 0 0 0.5rem;
          font-size: 1rem;
          &::marker {
            font-size: 1.125em;
            margin-right: 0.5rem !important;
          }
        }
      }
    }
    @media screen and (min-width: $lg-min) {
      .container {
        flex-direction: row;
      }
      .column-one {
        flex-basis: 35%;
      }
      .column-two {
        flex-basis: 65%;
        padding: 0 2rem;
      }
    }
  }
}
a.card {
  &:hover {
    .heading {
      .heading-title {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }
}

.Grid--item.col__lg-6:not(.col__xl-4) {
  .card.featured {
    .container {
      flex-direction: column;
      .column-one {
        margin-block-end: 1.5rem;
      }
      .column-two {
        margin-inline-start: 0;
        .heading-title {
          margin-block-end: 1rem;
        }
      }
    }
  }
}


.Grid--item a{
    text-decoration:none;

}

.card.stats.cardBorder {
    border: none !important;
    --bs-card-bg: none !important;
}
.card.callout-shadow {
    border: none;
    padding: 16px;
    max-width: 415px;
    margin: 0 auto;

    a {
        text-decoration: none;
    }
}

.card.callout-shadow .container {
    flex-direction: row;
    gap: 12px;
    transition: all 0.1s ease;
}

.card.callout-shadow .container .image-wrapper {
    width: 42px;
}

.card.callout-shadow .button-wrapper {
    margin-left: auto;
    margin-right: 5%;
    margin-top: 0px;
    pointer-events: none;
}

.card.callout-shadow .heading {
    transition: all 0.1s ease;
}

.card.callout-shadow .description {
    margin-bottom: 13px;
    width: 95%;
}

.card.callout-shadow .description p {
    font-weight: 400;
}

.card.callout-shadow .call-to-action a {
    padding: 5px 0;
    transition: all 0.1s ease;
}

@each $key, $val in $colorMap {
    .card.stats .stats__heading .heading .heading-title {
        &.font-#{$key} {
            color: $val !important;
        }

        &.bg-#{$key} {
            background-color: $val !important;
        }
    }

    .card.stats .description {
        &.font-#{$key} {
            color: $val !important;
        }

        &.bg-#{$key} {
            background-color: $val !important;
        }
    }

    .card.stats .stats__heading .heading {
        &.bg-#{$key} {
            &:before {
                background-color: $val !important;
            }
        }
    }
    .card.callout-shadow:hover .container {
        &.shadow-bg-#{$key} {
            box-shadow: 13px 13px 2px 0px $val;
        }
    }


    .card.callout-shadow {
        &:hover {
            .heading-title {
                &.hover-font-#{$key} {
                    color: $val;
                }
            }
        }
    }

    .card.callout-shadow {
        &:hover {
            .description {
                &.hover-font-#{$key} {
                    color: $val;
                }
            }
        }
    }

    .card.callout-shadow {
        &:hover {
            .button-wrapper {
                &.hover-font-#{$key} .call-to-action span {
                    color: $val;
                }
            }
        }
    }
    .card.callout-hover-bg {
        &:hover {
            .heading-title {
                &.hover-font-#{$key} {
                    color: $val;
                }
            }
        }
    }

    .card.callout-hover-bg {
        &:hover {
            .description {
                &.hover-font-#{$key} {
                    color: $val;
                }
            }
        }
    }

    .card.callout-hover-bg {
        &:hover {
            .button-wrapper {
                &.hover-font-#{$key} .call-to-action span {
                    color: $val;
                }
            }
        }
    }

    .card.callout-hover-bg.hover-bg-#{$key} {
        &:hover {
            background: $val;
        }
    }
    .card.callout-hover-bg.bg-#{$key} {       
            background: $val;        
    }
    .card.center-aligned.bg-#{$key} {
        background: $val;
    }
}

.card.column-4-layout, .card.column-2-layout {
    border: none;
    font-family: "Inter", sans-serif;
}

.column-2-layout .heading .heading-title, .column-4-layout .heading .heading-title {
    color: #000000;
    margin: 0px;
}

.card.column-2-layout .description p, .card.column-2-layout .card.column-2-layout .description > * {
    max-width: 550px;
    margin: 0 auto;
}

$fontsizes: ( 'font-size-24': 24px, 'font-size-20': 20px, 'font-size-16': 16px );
$fontweights: ( 'font-weight-700': 700, 'font-weight-600': 600, 'font-weight-400':400 );

@each $key, $val in $fontsizes {


    .card.column-2-layout .heading.#{$key} .heading-title, .card.column-4-layout .heading.#{$key} .heading-title {
        font-size: $val;
        line-height: 29.05px;
    }

    .card.column-2-layout .#{$key}.description > * {
        font-size: $val;
        line-height: normal;
    }
    .card.callout-hover-bg .heading.#{$key} .heading-title {
        font-size: $val;
    }

}


@each $key, $val in $fontweights {

    .card.column-2-layout .heading.#{$key} .heading-title, .card.column-4-layout .heading.#{$key} .heading-title {
        font-weight: $val;
    }

    .card.column-2-layout .#{$key}.description > * {
        font-weight: $val;
    }

    .card.callout-shadow .#{$key}.description p {
        font-weight: $val;
    }
    .card.callout-hover-bg .#{$key}.description p {
        font-weight: $val;
    }
}

.card.column-4-layout figure, .card.column-2-layout figure {
    margin: 0 0 10px;
}

.card.column-2-layout .container {
    padding: 5px 25px 20px;
}

.column-2-layout .heading {
    margin-bottom: 7px;
}




.card.callout-hover-bg {
    border: none;
    padding: 0px 15px;
    font-family: "Inter", sans-serif;

    a {
        text-decoration: none;
    }
}

.card.callout-hover-bg .container {
    flex-direction: row;
    gap: 10px;
    transition: all 0.1s ease;
    padding: 1rem;
    border-radius: 13px;
}



.card.callout-hover-bg .container .image-wrapper {
    width: 60px;
    margin: 0px;
}

.card.callout-hover-bg .container .image-wrapper figure {
    margin: 0px;
}

.card.callout-hover-bg .heading .heading-title {
    line-height: 20px;
    margin-bottom: 5px;
}

.card.callout-hover-bg .description {
    width: 100%;
    margin: 0;
}

.card.callout-hover-bg .description > * {
    line-height: 19.36px;
    margin: 0px;
}

.callout-hover-bg .hover-textdecor-underline {
    text-decoration: none !important;
}
.card.callout-hover-bg {
    .button-wrapper {
        display: block;
        .call-to-action {
            a {
                padding: 0rem;
            }

            .call-to-action-content {
                padding: 0.8rem 0rem;
            }
        }
    }
}

@media (max-width:767px) {
    @each $key, $val in $colorMap {

        .card.callout-shadow:hover .container {
            &.shadow-bg-#{$key} {
                box-shadow: 12px 12px 1px 0px $val;
            }
        }
    }

    .card.callout-shadow {
        padding: 13px;
    }

    .card.callout-shadow .container {
        gap: 12px;
        padding: 0 10px 10px;
    }

    .card.callout-shadow .container .image-wrapper {
        width: 38px;
    }

    .card.column-2-layout .container {
        padding: 7px 5px 20px;
    }
}
