.secondary-nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 99;
  @media (min-width: $desktop-break-min) {
    position: relative;
    background-color: $background-color-10;
    padding: 1rem 3.5rem;
  }
  .mobile {
    display: flex;
    & .secondary-nav__togglebutton {
      display: flex;
      align-items: center;
      i.fa-solid {
        color: $font-color-16;
      }
      & .fa-chevron-up {
        display: inline-block;
      }
      & .fa-chevron-down {
        display: none;
      }
      &.hide-icon {
        & .fa-chevron-up {
          display: none;
        }
        & .fa-chevron-down {
          display: inline-block;
        }
      }
    }
    & .menu__items {
      background-color: $font-color-16;
      order: 1;
      width: 100%;
      padding: 1.5rem;
      flex-direction: column;
      flex-wrap: nowrap;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      translate: 0 100%;
      visibility: hidden;
      padding-top: 4rem;
      transition: translate 0.25s ease-in;
      & > .call-to-action {
        a {
          justify-content: flex-start;
          text-decoration: underline;
          .cta-text {
            color: $font-color-3;
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }
      & .secondary-nav__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        gap: 0;
        & > .call-to-action {
          border-bottom: 1.5px solid $font-color-7;
          a {
            justify-content: flex-start;
          }
          .cta-text {
            color: $font-color-3;
            font-weight: bold;
          }
        }
      }
      &.expand-menu {
        translate: 0 0;
        visibility: visible;
        transition: translate 0.25s ease-in;
      }
      & .secondary-nav__cta {
        .call-to-action {
          background-color: $font-color-5;
          margin-block-start: 2rem;
          .cta-text {
            color: $font-color-3;
          }
        }
      }
    }
    & .menu__bar {
      background-color: $background-color-10;
      order: 2;
      width: 100%;
      padding: 1.5rem;
      position: relative;
      z-index: 99;
      display: flex;
      & .secondary-nav__header {
        display: flex;
        flex: 1;
        .call-to-action {
          a {
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
    & .list-cta.mobile {
      color: $font-color-3;
      border-bottom: 1.5px solid $font-color-7;
      padding: 1rem 0;
      width: 100%;
      .heading-title {
        color: $black;
      }
      &:nth-child(1) {
        border-top: 1.5px solid $font-color-7;
      }
      &:last-child {
        border-bottom: none;
      }
      & .list-cta-accordion-heading {
        .heading-title {
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 0;
        }
        .expand-all {
          i {
            color: $font-color-3;
            &::before {
              content: '\f078';
            }
          }
        }
        &.active {
          .expand-all {
            i {
              &::before {
                content: '\f077';
              }
            }
          }
        }
      }
      & .list-cta-accordion-content {
        .call-to-action {
          a {
            padding: 0.5rem 0 0;
            .cta-text {
              font-size: 0.85rem;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
        }
      }
      & button {
        &.collapsible {
          margin: 0;
        }
        .heading-title {
          color: $font-color-3;
        }
      }
      & .call-to-action {
        a {
          justify-content: flex-start;
        }
        .cta-text {
          color: $font-color-3;
        }
      }
    }
    @media (min-width: $desktop-break-min) {
      display: none;
    }
  }

  .desktop {
    display: none;
    @media (min-width: $desktop-break-min) {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      & .secondary-nav__header {
        display: flex;
        justify-content: flex-start;
        .call-to-action {
          a {
            padding: 0;
            .cta-text {
              font-size: 1.125rem;
              color: $font-color-16;
              margin: 0;
            }
          }
        }
      }
      .list-cta.Grid--container {
        display: flex;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        background-color: $font-color-16;
        position: absolute;
        top: 100%;
        padding: 1rem;
        margin-block-start: 0.5rem;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
        width: max-content;

        .call-to-action {
          width: 100%;
          border-bottom: 2px solid $font-color-2;
          &:last-child {
            border-bottom: none;
            a {
              padding-block-end: 0;
            }
          }
          &:first-child {
            a {
              padding-block-start: 0;
            }
          }
          a {
            padding: 0;
            padding-block: 0.5rem;
            justify-content: flex-start;
            .cta-text {
              font-size: 1rem;
              color: $font-color-3;
              text-transform: capitalize;
            }
          }
        }
      }
      & .secondary-nav__cta {
        flex: 0 1 auto;
        display: flex;
        justify-content: flex-end;
        a {
          &:hover {
            .cta-text {
              color: $background-color-10;
            }
          }
        }
      }
      & .secondary-nav__links {
        display: flex;
        gap: 2.5rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        .heading-title {
          font-size: 1rem;
          display: flex;
          align-items: center;
        }
        & .list-cta.desktop {
          flex-wrap: nowrap;
          flex-direction: column;
          position: relative;
          & .heading-title {
            font-size: 1rem;
            font-weight: normal;
            margin: 0;
            cursor: pointer;
            position: relative;
            & i.margin-left {
              margin-left: 0.5rem;
              &::before {
                content: '\f078';
              }
            }
          }
          &.show-menu {
            & .heading-title {
              i::before {
                content: '\f077';
              }
            }
            & .list-cta.Grid--container {
              opacity: 1;
              visibility: visible;
              transition: opacity 0.2s ease-in;
            }
          }
        }
        & > .call-to-action {
          border-bottom: none;

          a {
            justify-content: flex-start;
            .cta-text {
              color: $font-color-3;
              font-size: 1rem;
              font-weight: bold;
              color: $font-color-16;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
