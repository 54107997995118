.webform {
    background-color: $font-color-2;
    position: relative;
    width: 100%;
    max-width: 32em;
    padding: 1rem;

    .close-button {
        display: none;
        @media (max-width: $xs-max) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 1.5625rem;
        }
    }

    .image-wrapper {
        text-align: center;

        img {
            width: 14.625rem;
            height: auto;
            margin: 0 auto;
        }
    }

    .heading {
        margin-bottom: 1.5rem;

        h3 {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            color: $font-color-3;
        }
    }

    form {

        label, option {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: .3rem;
            color: $font-color-9;
        }
        input, select {
            width: 100%;
            height: 3.625rem;
            border: white;
        }

        .form-checkbox {
            list-style:none;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
        }

        select {
            font-size: 1rem;
            font-weight: 700;
            color: $font-color-9;
            padding: 1rem;
        }

        input[type=checkbox] {
            width: 1.5rem;
            height: 1.5rem;
            background-color: $font-color-3;
            color: white;
            margin-right: 1rem;
        }

    }

    .description {
        p {
            color: $font-color-9;
            font-size: 1.25rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0;
        }
    }

    .button-wrapper {
        margin-top: 1.5rem;
        text-align: center;
        .button {
            @media (max-width: $xs-max) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .url {
        margin-top: 1.25rem;
        text-align: center;
    }

    &.StickyButton,
    &.MobileKnownVisitor {
        background: $background-color-10;
        color: white;

        .heading {
            margin: 0;
        }

        .description p {
            color: white;
        }
        
        .button-wrapper {
            .button {
                @media (max-width: $xs-max) {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        .url a {
            color: white;
        }
    }
}