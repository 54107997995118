﻿$xs-min: 320px; // 0rem; old
$xs-max: 480px; // 360px old
$sm-min: 481px; //361px old
$sm-max: 768px; //655px old
$md-min: 769px; //656px old
$md-max: 1024px; //1023px old
$lg-min: 1025px; //1024px old
$lg-max: 1200px; //1439px old
$xl-min: 1201px; //1440px old
$xl-max: 1440px; //1919px old
$xxl-min: 1441px; //1920px old

// Please refer to the designs at
// https://app.zeplin.io/project/6388c3251710287cc7ce36dc/screen/6499aee7e2c0702396afe4f0
// when creating mobile breakpoint media queries
$mobile-break-max: 1199px; // 1199px
$desktop-break-min: 1200px; // 1200px
$content__max-width: 100rem;

.hide-on-desktop {
    @media (min-width: $desktop-break-min) {
        display: none;
    }
}

.hide-on-mobile {
    @media (max-width: $mobile-break-max) {
        display: none;
    }
}
@mixin tablet-up {
    @media screen and (min-width: $lg-min) {
        @content;
    }
}

