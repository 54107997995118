.rich-text-block {
  .description {
    margin-bottom: 1.5rem;
    & > * {
      margin-bottom: 1.5rem;
      &:is(h1, h2, h3, h4) {
        margin-top: 3.125rem;
      }
    }
    ul,
    ol {
      & > * {
        padding-left: 40px;
      }
    }
  }
  @media (min-width: $lg-min) {
    .description {
      margin-bottom: 2rem;
      & > * {
        margin-bottom: 2rem;
      }
    }
  }
}
