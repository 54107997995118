.product-feed-wrapper {
  width: 100vw;
  @media (max-width: $sm-max) {
    padding: 0 1.4375rem;
  }

  .title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100rem;
    width: 100%;
    margin-block: 5rem 3.5rem;
    gap: 2rem;
    @media (max-width: $sm-max) {
      margin-bottom: 3rem;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }

    .heading-title {
      font-size: 1.5rem;
      color: $font-color-3;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
    }

    .select-wrapper {
      position: relative;
      max-width: 24.5rem;
      width: 100%;
      border: 0.0625rem solid $font-color-3;
      border-radius: 0;
      font-size: 1rem;
      cursor: pointer;
      background: transparent;
      margin: 0;
      @media (max-width: $sm-max) {
        margin-bottom: 1.25rem;
      }
      .fa-chevron-down {
        display: block;
        pointer-events: none;
      }
      .fa-chevron-up {
        display: none;
      }

      &.show {
        .fa-chevron-down {
          display: none;
        }
        .fa-chevron-up {
          display: block;
        }
      }

      select {
        width: 100%;
        font-size: 1rem;
        text-transform: uppercase;
        appearance: none;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 1rem 3.5rem 1rem 1rem;
        margin: 0;
        cursor: pointer;
        position: relative;
        user-select: none;
        option {
          cursor: pointer;
        }
        ::-ms-expand {
          display: none;
        }
      }

      .fa-solid {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        width: 8%;
        fill: $font-color-3;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
      }
    }

    .button {
      cursor: pointer;
      &.text {
        font-size: 1rem;
        color: $font-color-3;
        margin: 0;
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-weight: 500;
        @media (max-width: $sm-max) {
          margin-bottom: 0;
        }
        span {
          margin: 0 0 0 0.5rem;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .js-product-feed-items {
    width: fit-content;
    margin: 0 auto;
  }
  .product-feed {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 auto 6.25rem 3rem;
    padding: 0;
    &.desktop {
      @media (max-width: $md-max) {
        display: none;
        margin: 0;
      }
    }

    &.mobile {
      display: none;
      max-width: 100%;

      @media (max-width: $md-max) {
        display: flex;
        flex-direction: column;
      }

      h3 {
        text-decoration: underline;
        text-decoration-color: $background-color-10;
        text-underline-offset: 0.5rem;
      }
    }

    li {
      list-style: none;
      max-width: 23.125rem;
      width: 100%;
      padding: 2rem;
      background: white;
      border: 0.0625rem solid $font-color-2;

      @media (max-width: $sm-max) {
        padding: 1.25rem;

        &.active {
          background: transparent;
        }
      }

      a {
        &:hover {
          text-decoration: none;
        }

        &.button {
          display: block;
          margin-top: 1.5rem;
          text-transform: uppercase;
          i {
            padding-left: 0.5rem;
          }
        }
      }

      img {
        height: 3.125rem;
        margin: 0 0 1.5rem 0;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        color: $font-color-3;
        margin: 0 0 1rem 0;
      }

      h4 {
        font-size: 1rem;
        font-weight: bold;
        color: $font-color-9;
        margin: 0 0 1.125rem 0;
      }

      p {
        font-size: 1rem;
        font-weight: normal;
        color: $font-color-3;
        margin: 0;
      }
      .secondary-description {
        margin-top: 1.125rem;
      }
    }
  }

  .item {
    margin: 0;
    background: transparent;
  }

  .item-header {
    position: relative;
    width: 100%;
    font-size: 1rem;
    color: $font-color-3;
    font-weight: normal;
    padding: 0.5rem 0 0 0;
    background: transparent;
    border-top: 0.0625rem solid $font-color-2;
    cursor: pointer;

    &::after {
      font-size: 1.5rem;
      position: absolute;
      right: 0;
      content: '\002B';

      @media (max-width: $sm-max) {
        font-size: 1rem;
      }
    }

    &.active {
      &::after {
        content: '\2212';
      }
    }
  }

  .item-body {
    display: none;

    &.active {
      display: block;
      padding: 1.5rem 0 0 0;
    }
    .button-wrapper {
      .call-to-action {
        a {
          justify-content: flex-start;
        }
      }
    }
  }
}
.Grid--container > .product-feed-wrapper {
  @media (max-width: $sm-max) {
    display: contents;
  }
}
