﻿$padding-xs: .25rem; // 4px
$padding-xs2: .5rem; // 8px
$padding-sm: 1rem; // 16px
$padding-sm2: 1.5rem; // 24px
$padding-md: 2rem; // 32px
$padding-md2: 2.5rem; // 40px
$padding-lg: 3rem; // 48px
$padding-xl: 4rem; // 64px
$padding-0: 0px;

$spacings: ( 
    "0":$padding-0, 
    "1":$padding-xs, 
    "2":$padding-xs2, 
    "3":$padding-sm, 
    "4":$padding-sm2, 
    "5":$padding-md, 
    "6":$padding-sm, 
    "7":$padding-md2, 
    "8":$padding-lg, 
    "9":$padding-xl 
);


@each $name, $value in $spacings {
    //Padding
    .p-top-#{$name} {
        padding-top: $value;
    }
    .p-bottom-#{$name} {
        padding-bottom: $value;
    }
    .p-left-#{$name} {
        padding-left: $value;
    }
    .p-right-#{$name} {
        padding-right: $value;
    }
    .m-#{$name} {
        padding: $value $value $value $value;
    }
    .p-vert-#{$name} {
        padding: $value 0px $value 0px;
    }
    .p-hor-#{$name} {
        padding: 0px $value 0px $value;
    }
}
