﻿body {
    & .call-to-action > a {
        text-decoration: none;
    }

    & > a {
        text-decoration: none;

        &:hover {
            text-decoration-thickness: 4px;
            text-underline-offset: 4px;
        }
    }
}
