.marketo-form {
    width: 100%;
    padding: 2rem;
    max-width: $content__max-width;
    margin: auto;
    --mktoFormCol-margin-bottom: 0.9375rem;

    .mktoFormRowHidden {
        display: none;
    }

    .heading-title {
        font-weight: normal;
        margin-bottom: 1.5rem;
        color: inherit;        
    }
    hr {
        margin-bottom: 1.5rem;
    }
    .mktoForm {
        width: 100% !important;
        font-size: 1rem !important;
        font-family: $font-family-2 !important;
        color: inherit !important;
        position:relative;
        //Classes when jquery validation is enabled for the form
        &.disable-marketo-builtin-validation {
            .mktoError {
               display:none !important;
            }
            .mktoFormRow.error input,
            .mktoFormRow.error select {
                background-color: rgb(255, 221, 221);
            }
            label.error {
                position:absolute;
                color: red;
                font-weight: bold;
                padding-left:10px;
                &::before {
                    vertical-align:middle;
                    content:"* "
                }
                height:16px;
                font-size:14px;
            }
            input,select {
                float:none;
            }
           
            .error-placeholder {
                display:block;
                position:relative;
                top:0;
                left:0;
                min-height:1px;
                min-width:1px;
            }
            .mktoFieldWrap {
                padding-bottom:10px;
            }
           
            
        }
        .mktoFormRow {
            width: 100% !important;
            
            .mktoFormCol {                
                width: 100% !important;
                margin-bottom: var(--mktoFormCol-margin-bottom) !important;
                .mktoFieldWrap {
                    width: 100% !important;
                }
            }

            .mktoField {
                width: 100% !important;
                height: 2.625rem;
                color: $font-color-3 !important;
                font-size: 1.125rem;
                font-weight: bold;
            }

           

            input[type="text"].mktoField,
            input[type="email"].mktoField,
            input[type="password"].mktoField,
            input[type="tel"].mktoField,
            select.mktoField,
            textarea.mktoField {
                box-sizing: border-box;
                padding: 1rem;
                border-radius: 10px;
                border:none;
                box-shadow: inset 2px 2px 6px #1b1b1b;
                height: 3.8rem;


                &:focus {
                    outline: 0;
                    //border: 1px solid rgb(81, 169, 252);
                    box-shadow: inset 0 0 2px 4px rgb(81, 169, 252);
                }
            }

            input[type="checkbox"] {
                padding: 1rem;
            }


            textarea.mktoField {
                height: 5.25rem;
            }

            .mktoField::placeholder {
                color: $font-color-9;
                font-size: 1rem;
                font-weight: 500;
            }
        }

        .mktoFormRow.mktoFormRowLogical {
            display: flex;

            .mktoFormCol {
                width: fit-content !important;

                .mktoLogicalField.mktoCheckboxList {
                    width: 2.125rem !important;
                    padding: 0;
                    margin-top: 1rem;
                    margin-right: 1rem;
                    /*input.mktoField[type="checkbox"] {
                        background: $font-color-16 !important;
                        color: $font-color-3 !important;
                    }*/
                    label.label-checkbox {
                        display: inline-block;
                    }

                    input.apple-switch {
                        position: relative;
                        -webkit-appearance: none;
                        outline: none;
                        background-color: $font-color-16;
                        border: 1px solid $font-color-3;
                        color: $font-color-3 !important;
                        transition: 0.4s;
                        vertical-align: middle;
                        height: auto;
                    }

                    input.apple-switch:checked:after {
                        content: '\f00c';
                        position: absolute;
                        top: 6px !important;
                        left: 9px !important;
                        transition: 0.4s;
                        font-family: FontAwesome;
                        font-weight: normal;
                    }
                }

                .mktoFieldWrap {
                    .mktoHtmlText {
                        width: 100% !important;
                        margin-top: 0.75rem !important;

                        a {
                            color: inherit !important;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .mktoButtonRow {
            width: 100% !important;
            text-align: center;
            margin: 1.5rem 0 0;

            span.mktoButtonWrap {
                margin-left: 0 !important;
            }

            .hybrid-form__embed .mktoButtonWrap:hover, .mktoButtonWrap:hover, span.mktoButtonWrap:hover {
                background-image: none !important;
                background-color: none !important;
            }

            .mktoButtonWrap.mktoSimple, .mktoButtonWrap.mktoRound, .roadshowFormButtonWrap {
                button.mktoButton, button.roadshowFormButton {
                    width: 100% !important;
                    padding-top: 1rem !important;
                    padding-bottom: 1rem !important;
                    background: $font-color-5;
                    color: $font-color-3;
                    font-size: 1.125rem;
                    font-weight: bold !important;
                    text-transform: uppercase;
                    border: none !important;
                    background-image: none !important;
                    border-radius: 0 !important;
                }

                button.mktoButton:hover {
                    background: $font-color-4;
                    color: $font-color-16;
                }
            }
        }
    }

    &.contact-form {
        .mktoForm {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;

            @media (min-width: ($sm-max + 1)) {
                .mktoFormRow {
                    width: 47% !important;
                }
            }

            .mktoButtonRow {
                width: auto !important;

                .mktoButtonWrap.mktoSimple, .mktoButtonWrap.mktoRound, .roadshowFormButtonWrap {
                    button.mktoButton, button.roadshowFormButton {
                        width: auto !important;
                    }
                }
            }
        }
    }

    &.newsletter-form {
        padding: 0;        
        .mktoForm {
            width: 100% !important;
            padding-bottom: calc(3.5rem - var(--mktoFormCol-margin-bottom));
            display: flex !important;
            @media only screen and (max-width: $sm-max){
                padding: 0 0 2.5rem 0 !important;
            }
            .mktoFormRow {
                width: auto !important;

                .mktoFormCol {
                    width: auto !important;
                }

                input:focus {
                    outline: none;
                }

                .mktoField.mktoEmailField {
                    width: 16rem !important;
                    height: 3.625rem;
                    padding: 1.3125rem 1.5rem;
                    border: none;                    
                }
            }

            .mktoButtonRow {
                width: auto !important;
                margin: 0;

                .mktoButtonWrap.mktoSimple, .mktoButtonWrap.mktoRound, .roadshowFormButtonWrap {
                    button.mktoButton, button.roadshowFormButton {
                        width: auto !important;
                        height: 3.625rem;
                        padding: 1.25rem 1.3125rem !important;
                    }

                    button.mktoButton:hover {
                        background: $font-color-4;
                        color: $font-color-16;
                    }
                }

                button.mktoButton::after {
                    content: '\f061';
                    font-family: FontAwesome;
                    font-weight: normal;
                    font-size: 1.125rem;
                }
            }
        }
    }
}

.footer .marketo-form.newsletter-form {
    @media (max-width: $sm-max) {
        padding-top: 2.5rem;
        margin-top: 1.25rem;
        border-top: solid 1px $font-color-10;
    }    
    .heading-title{
        @media (max-width: $sm-max) {
            margin-bottom: 2.5rem;
        }
    }    
}

.marketo-form form.mktoForm {
    font-family: "Inter", sans-serif !important;
}

.marketo-form .mktoForm .mktoFormCol .mktoRequiredField .mktoField, .marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap select.mktoField {
    border: 1px solid #D9D9D9 !important;
    box-shadow: none !important;
    height: 48px !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #00202E !important;
    border-radius: 4px !important;
    padding: 6px 14px !important;
}

.marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap select.mktoField {
    padding: 6px 14px 6px 12px !important;
}

.marketo-form .mktoForm .mktoFormCol .mktoRequiredField .mktoField::placeholder {
    color: #00202E !important;
}

.marketo-form form.mktoForm .mktoFormRowLogical .mktoFormCol {
    margin: 0px !important;
    min-height: auto;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoFieldWrap .mktoHtmlText {
    margin: 0px !important;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoLogicalField.mktoCheckboxList {
    margin: 0 10px 0 0;
    width: 16px !important;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoFieldWrap .mktoHtmlText {
    margin: 0px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoFieldWrap .mktoHtmlText a {
    color: #0072B5 !important;
    text-decoration: none;
}

.marketo-form .mktoForm .mktoFormRow.mktoFormRowLogical {
    align-items: center;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoLogicalField.mktoCheckboxList .apple-switch {
    height: 16px !important;
    width: 16px !important;
    padding: 0px !important;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoLogicalField.mktoCheckboxList label {
    margin: 0;
    min-height: auto;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoLogicalField.mktoCheckboxList input.apple-switch:checked:after {
    top: -3px !important;
    left: 2px !important;
    font-size: 12px;
}

.marketo-form form.mktoForm .mktoButtonRow {
    text-align: left;
}

.marketo-form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoSimple button.mktoButton {
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 19.36px;
    border: 1px solid #00202E !important;
    box-shadow: 4px 4px 0px 0px #00202E;
    border-radius: 8px !important;
    height: 44px;
    padding: 8px 20px !important;
    width: auto !important;
    text-transform: none;
    letter-spacing: 0.5px !important;
}

.marketo-form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoSimple button.mktoButton:hover {
    box-shadow: none;
}

.marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoRequiredField {
    position: relative;
    padding-bottom: 0px;
}

.marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoRequiredField label, .marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap label {
    position: absolute;
    left: 12px;
    top: 17px;
    padding: 0 4px;
    line-height: 16px;
    font-size: 16px;
    font-weight: 400;
    transition: all .15s ease-in-out;
    color: #00202E !important;
    pointer-events: none;
}

.marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoRequiredField:has(input:focus) label, .marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoRequiredField:has(input:not(:placeholder-shown)) label, .marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap:has(select:focus) label, .marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoFieldWrap:has(select:valid) label {
    top: -6px;
    background: #fff;
    font-weight: 500;
}

.marketo-form .mktoForm .mktoFormCol .mktoRequiredField:has(label) .mktoField::placeholder {
    opacity: 0 !important;
}

.marketo-form form.mktoForm .mktoFormRow.mktoFormRowLogical .mktoFormCol .mktoFieldWrap .mktoHtmlText br ~ br {
    display: none;
}

.marketo-form .mktoForm .mktoFormRow .mktoFormCol .mktoRequiredField .error-placeholder label {
    position: static !important;
    color: red !important;
    font-size: 15px !important;
    background: none !important;
    font-weight: 400 !important;
}
.modal-container .marketo-form .mktoForm .mktoFormRow.mktoFormRowLogical {
    margin-bottom: 15px;
}