.gallery-carousel {
  $slide-aspect-width: 560;
  $slide-aspect-height: 315;
  $slider-max-width: 900px;
  $thumb-padding-width: $padding-xs2;
  $thumb-width: 13.5rem;
  --custom-swiper-space-between: 0;
  --swiper-navigation-sides-offset: 0;
  width: 100%;
  margin: 0 auto;
  .swiper-pagination {
    position: relative !important;
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
    }
  }
  iframe {
    max-width: 100%;
    max-height: 100%; // prevent overflow in container
  }
  &__wrapper {
    width: 100%;
    max-width: 100rem;
    margin: 1.5rem auto 3.75rem;
    padding: 0 1.5rem;
    position: relative;
    .heading-title {
      max-width: 900px;
      font-size: 2rem;
      margin: 0 auto 2rem;
      padding: 0;
    }
    @media (min-width: $lg-min) {
      .heading-title {
        margin: 0 auto 2.75rem;
        font-size: 2.5rem;
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      object-fit: contain;
    }
    & > * {
      width: inherit;
      max-width: calc(100% - 32px);
      max-height: 100%;
      min-height: 0;
      min-width: 0;
    }
  }
  &__thumb-slider {
    .swiper-wrapper {
      display: none;
    }
    .swiper-slide {
      cursor: pointer;
    }

    @media (min-width: $lg-min) {
      .swiper-wrapper {
        display: flex;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin: 0;
    padding: 2.125rem 1.5rem; // 34px 24px
    background-color: $font-color-2;
    transform: translateY(-50%);
    display: none;
    @media (min-width: $lg-min) {
      display: flex;
    }
  }
  .image-block {
    padding: 0;
  }
  &__main-slider {
    // height: 240px; // Added extra pixels to include pagination within the main slider
    .swiper-wrapper {
      height: calc((100vw / $slide-aspect-width) * $slide-aspect-height);
      .image-block {
        img {
          height: calc((100vw / $slide-aspect-width) * $slide-aspect-height);
          max-width: 100%;
        }
      }
    }
  }
  @media (min-width: $lg-min) {
    max-width: $slider-max-width;
    &__main-slider {
      display: block;
      height: calc(($slider-max-width/$slide-aspect-width) * $slide-aspect-height);
      margin-block-end: $margin-md;
      .swiper-wrapper {
        height: calc(($slider-max-width/$slide-aspect-width) * $slide-aspect-height);
        .image-block {
          max-width: $slider-max-width;
          padding: 0;
          img {
            height: calc(($slider-max-width/$slide-aspect-width) * $slide-aspect-height);
          }
        }
      }
    }

    &__thumb-slider {
      .image-block {
        padding: 0;
      }
      iframe {
        pointer-events: none;
        max-width: calc(#{$thumb-width} - #{$thumb-padding-width * 2});
        max-height: calc(#{$thumb-width} - #{$thumb-padding-width * 2});
        img {
          height: 100%;
        }
      }

      .gallery-carousel__slide {
        max-width: $thumb-width;
        padding: 0;
        margin-inline-end: $thumb-padding-width;
        aspect-ratio: 1 / 1;
        background-color: transparent;
        border: solid 1px $font-color-9;
        & > div {
          padding: 0;
          display: flex;
          align-items: center;
        }
        &:first-child {
          margin-inline-end: 10px;
        }
        &:last-child {
          margin-inline-end: 0;
        }

        & > * {
          padding: $thumb-padding-width;
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}
