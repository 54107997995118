.banner {
  position: relative;
  z-index: 3;
  padding: 0 1.25rem;
  margin: 0 0 2rem;
  & .close-sticky {
    display: flex;
    gap: 0.5rem;
    font-weight: 300;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
    z-index: 4;
    top: 15%;
    translate: 0 -50%;
    cursor: pointer;
    span,
    i {
      color: $font-color-16;
      vertical-align: middle;
    }
    @media screen and (min-width: $lg-min) {
      right: 1rem;
      margin-right: 1rem;
      z-index: 4;
      top: 50%;
      translate: 0 -50%;
    }
  }
  &.close-alert {
    display: none;
  }
  &.hide-on-load {
    translate: 0 99.999%;
    transition: all 0.3s ease-in;
    @media screen and (min-width: $lg-min) {
      position: static;
    }
  }
  &.stick {
    &-top {
      &-desktop {
        position: relative;
        @media screen and (min-width: $sm-min) {
          // position: absolute;
          bottom: unset;
          width: 100%;
        }
      }
      &-mobile {
        position: absolute;
        bottom: unset;
        top: 0;
        @media screen and (min-width: $lg-min) {
          position: relative;
        }
      }
    }
    &-bottom {
      &-desktop {
        bottom: 0;
      }
      &-Mobile {
        position: fixed;
        bottom: 0;
        width: 100vw;
        transition: all 0.3s ease-in;
        & .close-sticky {
          top: 1em;
          right: 1em;
          // margin: 1.25rem 2rem;
        }
        @media screen and (min-width: $lg-min) {
          position: relative;
          & .close-sticky {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (min-width: $lg-min) {
    padding: 0 3rem;
  }
  &__background_image {
    position: absolute;
    inset: 0;
    z-index: 2;
    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
    & ~ .banner__content_wrapper {
      & .banner__text_wrapper {
        & .heading-title,
        & .banner__description {
          & > * {
            color: $font-color-16;
          }
        }
      }
    }
  }
  &__overlay {
    &_top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      z-index: 1;
    }
    &_bottom {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 1;
    }
  }
  &__image_wrapper {
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    z-index: 3;
    flex-basis: 100%;
    max-width: 100%;
    @media screen and (min-width: $lg-min) {
      flex-basis: 16.66666666666667%;
      max-width: 16.66666666666667%;
    }
  }

  &__content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    z-index: 3; // show content on specific banners

    @media screen and (min-width: $lg-min) {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      flex-basis: 83.33333333333333%;
      max-width: 83.33333333333333%;
    }
  }

  &__text_wrapper {
    @media screen and (min-width: $lg-min) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 50%;
    }

    & .banner__description {
      text-align: center;
      @media screen and (min-width: $lg-min) {
        text-align: left;
      }
    }
  }

  &__button_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem; // 24px

    @media screen and (min-width: $lg-min) {
      flex-wrap: nowrap;
      gap: 2.25rem;
      flex: 1 1 50%;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: $content__max-width;
    margin: 0 auto;
    &.bg-midnight-main,
    &.bg-cerulean-main {
      & .banner__content_wrapper {
        & .banner__text_wrapper {
          & .heading-title,
          & .banner__description {
            h3,
            h4,
            h5,
            h6,
            p,
            span {
              color: $font-color-16;
            }
          }
        }
      }
    }
    @media (min-width: $lg-min) {
      padding: 2rem 8rem;
      flex-direction: row;
    }
  }

  &.alert {
    & .banner__inner {
      padding: 1rem 0;
      @media screen and (min-width: $lg-min) {
        max-width: 50vw;
      }
    }
    & .banner__content_wrapper {
      display: table;
      @media screen and (min-width: $lg-min) {
        display: flex;
      }
    }
    & .banner__text_wrapper {
      display: table-cell;
      & .heading-title {
        font-size: 1.125rem;
        margin-bottom: 0;
      }
      & .banner__description {
        line-height: 1.6;
        font-size: 1rem;
        text-align: left;
        h4,
        h5,
        p,
        span {
          font-size: 1rem;
          line-height: 1.6;
          font-weight: bold;
          text-align: left;
        }
        @media screen and (min-width: $lg-min) {
          font-size: 1.125rem;
          text-align: center;
          h4,
          h5,
          p,
          span {
            font-size: 1.125rem;
            text-align: center;
          }
        }
      }
    }
    & .banner__button_wrapper {
      .call-to-action {
        a {
          padding: 0;
        }
        .cta-text {
          display: none;
          @media screen and (min-width: $lg-min) {
            display: table-cell;
          }
        }
      }
    }
  }
  &.award {
    & .banner__inner {
      padding: 2.5rem 0.5em 5.9375rem 0.5rem;
      @media (min-width: $lg-min) {
        padding: 3.1875rem 2rem;
        &::before,
        &::after {
          display: none;
        }
      }
    }
    & .banner__image_wrapper {
      align-items: center;
      img {
        max-width: 100%;
        max-height: 12rem;
        min-width: 8rem;
      }
    }
    & .banner__content_wrapper {
      display: flex;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-left: 0;
      text-align: left;
      @media screen and (min-width: $lg-min) {
        padding-left: 1.5rem;
        padding-block: 1.5rem;
        justify-content: center;
      }
      & .banner__text_wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        & .heading-title {
          font-size: 1.75rem;
          @media screen and (min-width: $lg-min) {
            font-size: 2rem;
            margin-bottom: 1rem;
          }
        }

        & .banner__description {
          margin-bottom: 1.75rem;
          font-size: 1.5rem;
          text-align: left;
          p {
            font-size: 1.5rem;
          }
        }
      }
      & .banner__button_wrapper {
        display: flex;
        align-items: flex-start;
        width: 100%;
        .call-to-action {
          a {
            justify-content: flex-start;
          }
        }
      }
      @media screen and (min-width: $lg-min) {
        align-items: flex-start;
      }
    }
    .banner__button_wrapper {
      .call-to-action {
        justify-content: center;
        width: 100%;

        &.cta-text {
          justify-content: flex-start;
          color: white;
        }
      }
    }
  }
  &.shortPromo {
    & .banner__inner {
      padding: 1rem;
      @media screen and (min-width: $lg-min) {
      }
      & .banner__content_wrapper {
        & .banner__text_wrapper {
          & .heading-title {
            font-size: 1.25rem;
            margin-bottom: 0;
            @media screen and (min-width: $lg-min) {
              margin-bottom: 0;
              font-size: 2rem;
            }
          }
        }
        text-align: center;
        @media screen and (min-width: $lg-min) {
          text-align: left;
          align-items: center;
        }
      }
    }
  }

  &.tallPromo {
    padding-inline: 1.5rem;
    @media (min-width: $lg-min) {
      padding-inline: 3.125rem;
    }
    .banner__inner {
      padding: 2rem 1.5rem;

      .banner__text_wrapper .heading-title {
        font-size: 2rem;
        margin: 0;
      }

      @media (min-width: $lg-min) {
        justify-content: center;
        padding: 3rem;
        max-width: none;

        .banner__content_wrapper {
          max-width: $content__max-width;
        }

        .banner__text_wrapper .heading-title {
          font-size: 2.5rem;
        }
      }
    }
    & .banner__button_wrapper {
      flex-direction: column;
      @media (min-width: $lg-min) {
        flex-direction: row;
      }
    }
  }
}
