.tabpanelcontainer-block {
  margin-top: 1.5rem;
  width: 100%;
  &__header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (min-width: $md-min) {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  }

  &__heading {
    text-align: center;
  }

  &__description {
    margin-bottom: 3rem;
    text-align: center;
  }
}

.tabcordion {
  padding: 0;
  .tab-set {
    flex-direction: column;
  }
  .container {
    padding: 0;
    @media (min-width: $lg-min) {
      padding: 0 2rem;
    }
  }
  .resp-tabs-list {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-bottom: 0.3125rem solid $font-color-2;
    &.horizontal-left {
      justify-content: flex-start;
    }

    &.horizontal-right {
      justify-content: flex-end;
    }
  }
  .resp-tabs-list li {
    font-weight: normal;
    font-size: 1.125rem;
    color: $background-color-7;
    text-align: center;
    display: inline-block;
    padding: 0.9375rem 1.4375rem;
    margin: 0;
    background: white;
    border: none;
    border-left: 0.3125rem solid $font-color-2;
    list-style: none;
    cursor: pointer;

    &:first-child {
      border-left: none;
    }

    &:not(.resp-tab-active) {
      &:hover {
        background: $background-color-7;
        color: $font-color-16;
      }
    }
  }
  .resp-tabs-container {
    border-top: none;
  }
  h2.resp-accordion {
    cursor: pointer;
    padding: 0.3125rem;
    display: none;
  }
  .resp-tab-content {
    display: none;
    flex-wrap: wrap;

    .container {
      width: auto;
      .row {
        gap: 2rem;
      }
    }
  }
  .resp-tabs-list .resp-tab-active {
    font-weight: bold;
    border: none;
    border-bottom: none;
    padding: 0.9375rem 1.4375rem;
    background: $font-color-2;
    border-left: 0.3125rem solid transparent;
  }
  .resp-content-active,
  .resp-accordion-active {
    display: block;
  }
  h2.resp-accordion {
    position: relative;
    font-size: 1.125rem;
    color: $font-color-3;
    font-weight: bold;
    margin: 0 0 1rem 0;
    padding: 1rem;
    background: $font-color-2;
    &::after {
      position: absolute;
      top: 1rem;
      right: 1.25rem;
      font-size: 1.125rem;
      font-weight: lighter;
      color: $font-color-3;
      content: '+';
    }
    &.resp-tab-active {
      color: $font-color-16;
      background-color: $background-color-7;

      &::after {
        right: 1.375rem;
        color: $font-color-16;
        content: '-';
      }
    }
    span {
      display: none;
    }
  }
  h2.resp-tab-active {
    margin-bottom: 0;
    padding: 1rem;
    background: white;
  }

  /*Here your can change the breakpoint to set the accordion, when screen resolution changed*/
  @media (max-width: $lg-min) {
    ul.resp-tabs-list {
      display: none;
    }
    h2.resp-accordion {
      display: block;
    }
    .resp-accordion-closed {
      display: none;
    }
    .tabpanelcontainer-block__tabpanel {
      margin-bottom: 1.5rem;
    }
  }
}
