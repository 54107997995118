﻿$margin-xs: .25rem; // 4px
$margin-xs2: .5rem; // 8px
$margin-sm: 1rem; // 16px
$margin-sm2: 1.5rem; // 24px
$margin-md: 2rem; // 32px
$margin-md2: 2.5rem; // 40px
$margin-lg: 3rem; // 48px
$margin-xl: 4rem; // 64px
$margin-0: 0px;

$spacings: ( 
    "0":$margin-0, 
    "1":$margin-xs, 
    "2":$margin-xs2, 
    "3":$margin-sm, 
    "4":$margin-sm2, 
    "5":$margin-md, 
    "6":$margin-sm, 
    "7":$margin-md2, 
    "8":$margin-lg, 
    "9":$margin-xl, 
);

@each $name, $value in $spacings {

    //Margins
    .m-top-#{$name} {
        margin-top: $value;
    }
    .m-bottom-#{$name} {
        margin-bottom: $value;
    }
    .m-left-#{$name} {
        margin-left: $value;
    }
    .m-right-#{$name} {
        margin-right: $value;
    }
    .m-#{$name} {
        margin: $value $value $value $value;
    }
    .m-vert-#{$name} {
        margin: $value 0px $value 0px;
    }
    .m-hor-#{$name} {
        margin: 0px $value 0px $value;
    }
}
