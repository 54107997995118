
@each $key, $val in $colorMap {
    .call-to-action,
    .call-to-action a {
        &:hover {
            .hover-font-#{$key} {
                color: $val;
            }

            &.hover-bg-#{$key} {
                background-color: $val;
            }

            &.hover-border-#{$key} {
                border-color: $val;
            }
        }

        &:focus {
            .focus-font-#{$key} {
                color: $val;
            }

            &.focus-bg-#{$key} {
                background-color: $val;
            }

            &.focus-border-#{$key} {
                border-color: $val;
            }
        }
    }


    .bottom-shadow {

        &.btn-style1 {

            &.shadow-bg-#{$key} {
                box-shadow: 0px 4px 0px 0px $val;
            }


            &.border-#{$key} {
                border: 1px solid $val !important;
            }


            &:hover {
                text-decoration: none;
                box-shadow: none;
                translate: 4px 4px;
            }
        }
    }

    .bottom-right-shadow {

        &.btn-style2 {
            &.shadow-bg-#{$key} {
                box-shadow: 4px 4px 0px 0px $val;
            }

            &.border-#{$key} {
                border: 1px solid $val !important;
            }

            &.color-#{$key} {
                color: $val !important;
            }

            &:hover {
                text-decoration: none;
                box-shadow: none;
                translate: 4px 4px;
            }
        }
    }


    .no-shadow {

        .btn-style3 {
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }
    }


    .transparent-underline-shadow {

        &.btn-style4 {

            &.shadow-bg-#{$key} {
                border-bottom: 4px solid !important;
            }

            &:hover {
                box-shadow: none !important;
                border-bottom: 1px solid !important;
            }
        }
    }
}

@each $key, $val in $opacityMap {
    .call-to-action,
    .call-to-action a {
        &:hover {
            &.hover-#{$key} {
                opacity: $val;
            }

            & .hover-#{$key} {
                opacity: $val;
            }
        }

        &:focus {
            &.focus-#{$key} {
                opacity: $val;
            }

            & .focus-#{$key} {
                opacity: $val;
            }
        }
    }
}
/* bootstrap override */
.container {
    & .row{
        & .call-to-action{
            width:auto;
        }

    }
}

.call-to-action {
    display: inline-block;
    position:relative;
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s ease;

    &.enable-fullwidth {
        width: 100%;
    }

    @media screen and (min-width: $lg-min) {
        display: inline-block;
        width: fit-content;

        &.enable-fullwidth {
            width: fit-content;
        }
    }

    &.disable-padding {
        button,
        a {
            padding-inline: 0;
        }
    }

    &.border {
        border-width: 0.125rem;
        border-style: solid;
    }

    button {
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
    }

    button,
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 1.125rem;
        outline: none;
        text-decoration: none;
        line-height: 1rem;
        letter-spacing: 0.0225rem;
        //padding: 0.8rem min(1.5rem, 2.5rem);
/*        width: 100%;
        height: 100%;*/

        &:hover {
            text-decoration: none;
        }

        & .cta-text {
            display: table-cell;
            font-size: min(0.8rem, 1rem);
            line-height: 1.6;
            word-wrap: break-word;

            @media screen and (min-width: $sm-min) {
                font-size: 1rem;
            }

            @media screen and (min-width: $lg-min) {
                font-size: 1.125rem;
            }
        }

        & .cta-subtitle {
            font-size: 0.875rem;
            line-height: 1.5;
        }
    }

    &-image {
        width: 35px;
        min-width: 35px;
        margin-right: 0.5rem;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &-sub {
            display: table;

            .cta-text {
                display: table-cell;
            }

            strong {
                display: table-cell;
            }
        }
    }

    &-iconbox {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        margin: 0 0.5rem;
    }

    &.certified {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

        a {
            padding-inline: 1.25rem;
            justify-content: flex-start;
        }

        .cta-text {
            font-size: 1.25rem;
            color: $font-color-3;
        }

        .call-to-action-image {
            width: 2.25rem;
            height: 2.25rem;

            img {
                max-width: 100%;
            }
        }

        @media screen and (min-width: $md-min) {
            align-items: center;

            .cta-text {
                font-size: 1.5rem;
            }

            .call-to-action-image {
                width: 2.75rem;
                height: 2.75rem;
            }
        }
    }
}


.call-to-action,
.call-to-action a {
    &:hover {
        .hover-textdecor-underline {
            text-decoration: underline;
        }

        .hover-textdecor-none {
            text-decoration: none;
        }
    }

    &:focus {
        .focus-textdecor-underline {
            text-decoration: underline;
        }

        .focus-textdecor-none {
            text-decoration: none;
        }
    }
}


.btn-style1, .btn-style2, .btn-style3, .btn-style4 {
    padding: 10px 15px;
    cursor: pointer;
    transition: 100ms ease;
    font-family: $font-family_1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 8px;
    min-width: 155px;
}


@each $key, $val in $animationMap {

    .btn-style1, .btn-style2, .btn-style3, .btn-style4 {

        &:focus {
            .focus-#{$key} {
                outline: none;
                animation: animationScale-#{$key} 0.3s forwards
            }
        }
    }

    .btn-style1, btn-style2, btn-style3, btn-style4 {
        &:visited {
            .visited-#{$key} {
                outline: none;
                animation: animationScale-#{$key} 0.6s forwards
            }
        }
    }

    .btn-style1, btn-style2, btn-style3, btn-style4 {
        &:active {
            .active-#{$key} {
                outline: none;
                animation: animationScale-#{$key} 0.2s forwards
            }
        }
    }

    @keyframes animationScale-#{$key} {
        0% {
            transform: scale(1);
            background-color: $background-color-5;
        }

        50% {
            transform: scale(1.1);
            background-color: $background-color-5;
        }

        100% {
            transform: scale(1);
            background-color: $background-color-5;
        }
    }
}