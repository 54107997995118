.product-card-block {
  max-width: 32rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

  @media screen and (min-width: $sm-min) {
    padding: 2.5rem;
  }

  &__image-wrapper {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__certified {
    margin-top: 1.65rem;
  }

  &__description {
    margin-top: 1.65rem;

    & > * {
      font-size: 1.5rem;
      color: $font-color-9;
    }
  }

  &__cta-wrapper {
    margin-top: 3rem;
  }
}
