.rd {
  &__wrapper {
    width: 100%;
    padding: 1.5rem;
    margin: 0 auto;
    position: relative;
  }
  &__main {
    padding-right: 2rem;
  }
  &__aside {
    width: 100%;
    max-width: 20rem;
    &_content-wrapper {
      background-color: $gray-light;
      padding: 1rem;
      .marketo-form {
        padding: 0;
        .mktoForm {
          padding: 0;
        }
      }
    }
  }
  @media screen and (min-width: $lg-min) {
    &__wrapper {
      gap: 2rem;
      padding: 5rem max(5rem, 8%);
    }
    &__aside {
      &_content-wrapper {
        padding: 1.25rem;
      }
    }
  }
}
