@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.montserrat-alternates-thin {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.montserrat-alternates-extralight {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.montserrat-alternates-light {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.montserrat-alternates-regular {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.montserrat-alternates-medium {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.montserrat-alternates-semibold {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.montserrat-alternates-bold {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.montserrat-alternates-extrabold {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.montserrat-alternates-black {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.montserrat-alternates-thin-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.montserrat-alternates-extralight-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.montserrat-alternates-light-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.montserrat-alternates-regular-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.montserrat-alternates-medium-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.montserrat-alternates-semibold-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.montserrat-alternates-bold-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.montserrat-alternates-extrabold-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.montserrat-alternates-black-italic {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 900;
    font-style: italic;
}



@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-Black.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-BlackItalic.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-Bold.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Basic';
    src: url('~/Presentation/fonts/Cera/CeraBasic-Regular.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraBasic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-Italic.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-Medium.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-Light.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('~/Presentation/fonts/Cera/CeraPro-MediumItalic.woff2') format('woff2'), url('~/Presentation/fonts/Cera/CeraPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('~/Presentation/fonts/MontSerrat/montserrat-v26-latin-regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 400;
    src: url('~/Presentation/fonts/InstrumentSans/instrument-sans-v1-latin-regular.woff2') format('woff2');
}
