@import './article-hero__article-info.scss';

.article-hero-block {
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: $md-max) {
    flex-direction: row;
  }

  &__background-image {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content-wrapper {
    position: relative;
    z-index: 2;
    flex-basis: 60%;
    padding: 1.5rem;

    @media (min-width: $md-min) {
      padding: 1.5rem 2.875rem 3.75rem 10rem;
    }
  }

  &__content {
    @media (min-width: $md-min) {
      padding-top: 1rem;
    }
  }

  h1 {
    line-height: 3.5rem;

    @media (min-width: $md-max) {
      line-height: 6rem;
    }
  }

  &__description {
    font-size: 1.25rem;
    margin: 2rem 0 2.5rem 0;

    @media (min-width: $md-max) {
      font-size: 1.5rem;
      margin: 1.875rem 0 4rem 0;
    }
  }

  &__cta-wrapper {
    .call-to-action {
      margin: 0 0 1.5rem 0;

      @media (min-width: $md-min) {
        margin: 0 2rem 1.5rem 0;
      }
    }
  }

  &__media-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 1.5rem;

    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }

    .video {
      display: inline-block;
      width: 100%;
    }
  }

  &.layout-large-image &__media-wrapper {
    padding: 0;
  }

  &.layout-small-image &__media-wrapper {
    @media (min-width: $md-max) {
      padding: 6rem 10rem 6rem 2rem;
    }
  }
}
