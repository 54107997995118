.timer--progress-container {
    width: 100%;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    height: 55px;
}


@keyframes opacity-blink
{
    0% {opacity:1}
    50% {opacity:0.3}
    100% {opacity:1}
}

@keyframes background-blink {
    0% {
        background-color: darkgreen    
    }
    50% {
        background-color: green
    }
    100% {
        background-color: darkgreen
    }
}


.timer--progress-bar {
    height: 100%;
    width: 0;
    background-color: #00202e;
    text-align: center;
    line-height: 55px;
    color: white;
    transition: width 1s linear;
}

.timer--progress-title {
    width: 100%;
    text-align: center;
    animation: opacity-blink 2s linear infinite;
}
.timer--progess-bar-completed {
    width: 100%;
    text-align: center;
    display: none;
}

#complete-button {
    display: none;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
