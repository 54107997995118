.hero-block {
  padding: 2% 5%;
  display: flex;
  position: relative;
  align-items:center;
  justify-content:center;
  flex-direction: column;
  /*@media (min-width: $lg-min) {
    flex-direction: row;
  }*/

  &__background-image {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content-wrapper {
    position: relative;
    z-index: 2;
    padding: 3rem 1.5rem 0 1.5rem;
    @media (min-width: $md-min) {
      padding: 4rem 1.5rem 0 1.5rem;
    }
    @media (min-width: $xl-min) {
      flex-basis: 55%;
      padding: 1.5rem 2.875rem 3.75rem min(10rem, 10%);
    }
  }

  &__content {
    @media (min-width: $lg-min) {
      padding-top: 1rem;
    }
    & > *[class*='heading-wrapper-'] {
      margin-bottom: 0;
    }
  }

  .heading-title {
    line-height: 3.75rem;

    @media (min-width: $md-min) {
      line-height: 6rem;
    }
  }
  h6.heading-title {
    line-height: normal;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
  }

  &__organisation {
    font-size: 1.25rem;
    margin: 1.5rem 0 2rem 0;
    & > * {
      font-size: 1.25rem;
      margin: 0;
    }
    @media (min-width: $lg-min) {
      width: 80%;
      font-size: 1.25rem;
      margin: 1.5rem 0 2.5rem 0;
      & > * {
        font-size: 1.5rem;
        margin: 0;
      }
    }
  }
  &__description {
    font-size: 1.25rem;
    margin: 2rem 0 2rem 0;
    & > * {
      font-size: 1.25rem;
      margin: 0;
    }
    @media (min-width: $lg-min) {
      width: 80%;
      font-size: 1.25rem;
      margin: 1.5rem 0 2.5rem 0;
      & > * {
        font-size: 1.5rem;
        margin: 0;
      }
    }
  }

  &__cta-wrapper {
    .call-to-action {
      margin: 0 0 1.5rem 0;

      @media (min-width: $lg-min) {
        margin: 0 2rem 1.5rem 0;
      }
    }
  }

  &__media-wrapper {
    position: relative;
    z-index: 2;
    display: flex;

    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 1.5rem;
    @media (min-width: $lg-min) {
      padding: 0;
      flex: 1 1 auto;
    }
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    .video {
      display: inline-block;
      width: 100%;
    }
  }

  &.layout-small-image {
    @media (min-width: $lg-min) {
      & .hero-block__content-wrapper {
        flex-basis: 70%;
      }
      & .hero-block__media-wrapper {
        flex-basis: 30%;
      }
    }
  }

  &.layout-no-image {
    .hero-block__content-wrapper {
      flex-basis: 100%;
    }

    @media (min-width: $lg-min) {
      .hero-block__description {
        width: 50%;
      }
    }
  }
}
.hero-block .row {
    align-items: center;
}
.hero-block .heading-title, .hero-block .heading-title * {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    margin:0;
}
.hero-block .hero-block__description * {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    font-family: "Inter", sans-serif;
}
/*start trails css start here*/
.hero-block__eyebrow p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.02em;
    color: #00202E;
}
.hero-block__eyebrow p strong{
    font-weight:400;
}
.hero-block.layout-no-image .hero-block__content .hero-block__description {
    width: 90%;
}
.hero-block.layout-no-image .hero-block__content .hero-block__description p:not(:last-child) {
    margin-bottom: 10px;
}
.hero-block.layout-no-image .hero-block__content .hero-block__description ul li:not(:last-child) {
    margin-bottom: 7px;
}
.hero-block.layout-no-image {
    justify-content: center;
}
.hero-block.layout-no-image .row {
    align-items: flex-start;
}
.marketo-form {
    padding: 25px !important;
    border: 1px solid #00202E;
    border-radius: 8px;
}
.marketo-form .heading-title {
    font-size: 22px;
    font-weight: 600 !important;
    line-height: 26.63px;
    font-family: "Inter", sans-serif;
    display: block;
    margin-bottom: 17px !important;
}

/*start trails css end here*/

@media (max-width:767px) {
    .hero-block .heading-title, .hero-block .heading-title * {
        font-size: 32px;
        line-height: 40px;
    }
}

@each $key, $val in $colorMap {
    body .hero-block .hero-block__title.heading-title {
        &.font-#{$key} {
            color: $val !important;
        }
    }

}