﻿/*$font-color-3: #1a1d36; Midnight Express Nav Link color,
$font-color-10: #4d5f80; Dark Electric Blue
$background-color-7: #24326a; Gulf Harbour Blue - Tab Background Color highlight

$font-color-8: #006a8e; Pantone 7786 C Cerulean    Font Background Color Highlight/Link Standard Color
$cerulean-light: #6ba4b8; Pantone 549 C light Cerulean
$background-color-10: #074f71; Pantone 2210 C Dark Cerulean

$font-color-5: #9eb356; Lime Green - Button Primary
$lime-light: #c4d6a4; Chelsea Cucumber
$font-color-4: #79863c; Wasabi Green
$font-color-6: #697533; Verdun Green - Primary Button Highlight Background
$incubus-red: #782327;*/

/* Palette - Grey */
//ScreenConnect styleguide colors:
$transparent: transparent;
//Greys
$zircon: #E5E9EA; //Zircon
//Teals
$dark-teal: #00202e;
$bold-teal: #1cb8b8;
$teal: #26f4f5;
$light-teal: #b8fafc;
//Blues
$mirage: #1c1f38;
$royal-lavender: #575cad;
$cornflower-blue: #747ce6;
$cold-lips: #a3a8ed;
$sky-blue: #00b2e3;
$gunmetal: #191c35;

//Reds
$seal-brown: #301716;
$red-velvet: #783a36;
$debutante-red: #f0746c;
$young-crab-red: #f5a29d;
$mulberry: #c14499;
$flame: #ee6323;

//Yellow
$lemon-curry: #cccb2f;

$white: #ffffff;
$black: #000000;

//Font
$font-color-1: $transparent;
$font-color-2: $zircon;
$font-color-3: $dark-teal;
$font-color-4: $bold-teal;
$font-color-5: $teal;
$font-color-6: $light-teal;
$font-color-7: $mirage;
$font-color-8: $royal-lavender;
$font-color-9: $cornflower-blue;
$font-color-10: $cold-lips;
$font-color-11: $seal-brown;
$font-color-12: $red-velvet;
$font-color-13: $debutante-red;
$font-color-14: $young-crab-red;
$font-color-15: $black;
$font-color-16: $white;
//Background
$background-color-1: $transparent;
$background-color-2: $zircon;
$background-color-3: $dark-teal;
$background-color-4: $bold-teal;
$background-color-5: $teal;
$background-color-6: $light-teal;
$background-color-7: $mirage;
$background-color-8: $royal-lavender;
$background-color-9: $cornflower-blue;
$background-color-10: $cold-lips;
$background-color-11: $seal-brown;
$background-color-12: $red-velvet;
$background-color-13: $debutante-red;
$background-color-14: $young-crab-red;
$background-color-15: $black;
$background-color-16: $white;
//Border
$border-color-1: $transparent;
$border-color-2: $zircon;
$border-color-3: $dark-teal;
$border-color-4: $bold-teal;
$border-color-5: $teal;
$border-color-6: $light-teal;
$border-color-7: $mirage;
$border-color-8: $royal-lavender;
$border-color-9: $cornflower-blue;
$border-color-10: $cold-lips;
$border-color-11: $seal-brown;
$border-color-12: $red-velvet;
$border-color-13: $debutante-red;
$border-color-14: $young-crab-red;
$border-color-15: $black;
$border-color-16: $white;


@mixin color-palette($property, $defaultValue) {
    #{$property}: $defaultValue;
}

$colorMap: ( 
    'zircon':$zircon,
    'dark-teal':$dark-teal, 
    'bold-teal':$bold-teal, 
    'teal':$teal, 
    'light-teal':$light-teal, 
    'mirage':$mirage, 
    'royal-lavender':$royal-lavender, 
    'cornflower-blue':$cornflower-blue, 
    'cold-lips':$cold-lips, 
    'seal-brown':$seal-brown, 
    'red-velvet':$red-velvet, 
    'debutante-red':$debutante-red, 
    'young-crab-red':$young-crab-red, 
    'white': $white, 
    'black': $black, 
    'mulberry': $mulberry,
    'flame': $flame,
    'lemon-curry': $lemon-curry,
    'sky-blue':$sky-blue,
    'gunmetal': $gunmetal
);

@each $key, $val in $colorMap {
    
    //Static
    .font-#{$key} {
        color: $val;
    }

    .bg-#{$key} {
        background-color: $val;
        --scoped-background-color: #{$val};
    }
    .border-#{$key} {
        border-color: $val;
    }    

    //Hover
    .h-font-#{$key} {
        &:hover {
            color: $key;
        }
    }
    .h-bg-#{$key} {
        background-color: $val;
        --scoped-background-color: #{$val};
    }

    .h-border-#{$key} {
        &:hover {
            border-color: $val;
        }
    }

    //Focus
    .f-font-#{$key} {
        &:focus {
            color: $key;
        }
    }
    .f-bg-#{$key} {
        &:focus {
            background-color: $val;
            --scoped-background-color: #{$val};
        }
    }
    .f-border-#{$key} {
        &:focus {
            border-color: $val;
        }
    }

    //Visited
    .v-font-#{$key} {
        &:visited {
            color: $val;
        }
    }
    .v-bg-font-#{$key} {
        &:visited {
            background-color: $val;
            --scoped-background-color: #{$val};
        }
    }
    .v-border-font-#{$key} {
        &:visited {
            border-color: $val;
        }
    }

    //Active
    .a-font-#{$key} {
        &:active {
            color: $val;
        }
    }
    .a-bg-font-#{$key} {
        &:active {
            background-color: $val;
            --scoped-background-color: #{$val};
        }
    }
    .a-border-font-#{$key} {
        &:active {
            border-color: $val;
        }
    }    
}


//Static
.transparent {
    color: transparent;
}

.bg-transparent {
    background-color: transparent;
    --scoped-background-color: transparent;
}
.border-transparent {
    border-color: transparent;
}

//Hover
.h-transparent {
    &:hover {
        color: transparent;
    }
}

.h-bg-transparent {
    &:hover {
        background-color: transparent;
        --scoped-background-color: transparent;
    }
}
.h-border-transparent {
    &:hover {
        border-color: transparent;
    }
}

//Focus
.f-transparent {
    &:focus {
        color: transparent;
    }
}
.f-bg-transparent {
    &:focus {
        background-color: transparent;
        --scoped-background-color: transparent;
    }
}
.f-border-transparent {
    &:focus {
        border-color: transparent;
    }
}
//Visited
.v-transparent {
    &:visited {
        color: transparent;
    }
}

.v-bg-transparent {
    &:visited {
        background-color: transparent;
        --scoped-background-color: transparent;
    }
}
.v-border-transparent {
    &:visited {
        border-color: transparent;
    }
}
//Active
.a-transparent {
    &:active {
        color: transparent;
    }
}
.a-bg-transparent {
    &:active {
        background-color: transparent;
        --scoped-background-color: transparent;
    }
}
.a-border-transparent {
    &:active {
        border-color: transparent;
    }
}
//Output Mapping for Color Inheritance
//Create placeholder values here instead of using the direct variables to reduce rework on future themes