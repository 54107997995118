﻿@each $key, $val in $colorMap {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.bg-#{$key} {
            display: inline;
            padding: 0.5rem;
            letter-spacing: 0.16rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        &.h-bg-#{$key} {
            display: inline;
            padding: 0.5rem;
            letter-spacing: 0.16rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        &.f-bg-#{$key} {
            display: inline;
            padding: 0.5rem;
            letter-spacing: 0.16rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-family: $font-family-1;
    font-weight: $font-weight-bold;    
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}