﻿
$opacityMap: ( 
    'opacity-100': 1, 
    'opacity-75': 0.75, 
    'opacity-50': 0.5, 
    'opacity-25': 0.25, 
    'opacity-0': 0, 
    );

@each $key, $val in $opacityMap {
    .hover-#{$key} {
        &:hover {
            opacity: $val;
        }
    }

    .focus-#{$key} {
        &:focus {
            opacity: $val;
        }
    }
}
