@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.38);
    opacity: 0;
    animation-name: fade;
    animation-duration: 0.3s;
    animation-delay: 0;
    animation-fill-mode: forwards;
    z-index: 100;
}

.site-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: calc(100% - 4rem);
    max-height: 52.625rem;
    padding: 2rem 5rem 2rem 2rem;
    background: white;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation-name: fade;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    z-index: 101;
    overflow: auto;

    @media (max-width: $sm-max) {
        position: fixed;
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        height: calc(100dvh - 50px);
        margin: 0;
        padding: 2.8125rem 1.5rem 1.5rem 1.5rem;
        transform: translateX(0);
        overflow: scroll;
    }

    .close-button {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;

        i {
            font-size: 2rem;
        }

        @media (max-width: $sm-max) {
            top: 1rem;
            right: 1rem;

            i {
                font-size: 1rem;
            }
        }
    }
}
