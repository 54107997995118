.blockable-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.big {
        .loader {
            width: 300px;
            height: 300px;
        }

        .caption {
            font-size: 35px;
        }
    }

    .caption {
        position: absolute;
        bottom: -50px;
        width: 100%;
        text-align: center;
        color: darkgray;
        animation:animateCaption 3s linear infinite
    }

    @keyframes animateCaption {
        0% {
            color: lightgray
        }

        50% {
            color: darkgray
        }

        100% {
            color: lightgray
        }
    }

    .loader {
        position: relative;
        width: 150px;
        height: 150px;
        border-radius: 50%;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid rgba(0, 0, 0, 0);
            border-top: 2px solid blue;
            border-left: 2px solid blue;
            box-sizing: border-box;
        }

        span:nth-child(1) {
            animation: animate3 linear 3s infinite;
        }

        span:nth-child(2) {
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            width: auto;
            height: auto;
            animation: animate1 linear 3s infinite
        }

        span:nth-child(3) {
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;
            width: auto;
            height: auto;
            animation: animate2 linear 3s infinite
        }

        @keyframes animate1 {
            0% {
                transform: rotate(20deg)
            }

            10% {
                transform: rotate(100deg)
            }

            25% {
                transform: rotate(100deg)
            }

            50% {
                transform: rotate(271deg)
            }

            60% {
                transform: rotate(271deg)
            }

            80% {
                transform: rotate(110deg)
            }

            90% {
                transform: rotate(110deg)
            }

            100% {
                transform: rotate(20deg)
            }
        }

        @keyframes animate2 {
            0% {
                transform: rotate(100deg)
            }

            10% {
                transform: rotate(45deg)
            }

            25% {
                transform: rotate(45deg)
            }

            50% {
                transform: rotate(180deg)
            }

            60% {
                transform: rotate(180deg)
            }

            80% {
                transform: rotate(222deg)
            }

            90% {
                transform: rotate(222deg)
            }

            100% {
                transform: rotate(100deg)
            }
        }

        @keyframes animate3 {
            0% {
                transform: rotate(200deg)
            }

            10% {
                transform: rotate(360deg)
            }

            25% {
                transform: rotate(360deg)
            }

            50% {
                transform: rotate(180deg)
            }

            60% {
                transform: rotate(180deg)
            }

            80% {
                transform: rotate(92deg)
            }

            90% {
                transform: rotate(92deg)
            }

            100% {
                transform: rotate(200deg)
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 24px;
            left: 24px;
            right: 24px;
            bottom: 24px;
            background: center/contain no-repeat url('~/Presentation/assets/images/pages/cw.svg');
            //border: solid rgb(61, 0, 202) 1px;
            border-radius: 50%;
            margin: 18px;
        }
    }
}
