.image-block {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content;
  min-height: auto;
  padding: 2.125rem 2.125rem 0 0;
  overflow-x: visible;

  @media (max-width: $md-max) {
    padding: 1.5rem 1.5rem 0 0;
  }

  &--border-style::before,
  &--border-style::after {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.5rem;
    font-weight: 500;

    @media (max-width: $md-max) {
      font-size: 1.125rem;
      letter-spacing: 0.35rem;
    }
  }

  &--border-style::before {
    content: '+ + + + + + + +';
    right: 0;
    top: 0.63rem;
    max-height: calc(100% - 1rem);
    writing-mode: vertical-lr;
    letter-spacing: 1rem;

    @media (max-width: $sm-max) {
      top: 0.43rem;
    }
  }

  &--border-style::after {
    content: '+ + + + + + +';
    right: 2.6rem;
    top: 0;
    max-width: calc(100% - 3rem);
    direction: rtl;
    letter-spacing: 1.25rem;

    @media (max-width: $md-max) {
      right: 1.875rem;
    }
  }

  &__background {
    display: inline-block;
    max-width: 100%;
    object-fit: contain;
    @media (max-width: $md-max) {
      width: 100%;
      right: 1.875rem;
    }
  }

  &__spotlight {
    background-color: var(--scoped-background-color);
    position: absolute;
    right: 0.125rem;
    bottom: -0.5rem;
    width: 25em;
    height: auto;
    flex-grow: 1;
    padding: 1.5rem;
    padding-bottom: 0.5rem;

    @media (max-width: $md-max) {
      position: relative;
      display: block;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }

  &__spotlight-logo {
    width: auto;
    height: 2.375rem;
  }
}
