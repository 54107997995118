﻿.list-cta {
  display: flex;
  flex-wrap: wrap;
  & .button {
    margin-bottom: 2rem;
    flex-basis: 100%;
    max-width: 100%;
    @media (min-width: $lg-min) {
      flex-basis: 41.66666666666667%;
      max-width: 41.66666666666667%;
    }
  }
  &.desktop {
    display: none;
    @media (min-width: $lg-min) {
      display: flex;
    }
  }

  // Mobile has the added accordion, which works in tandem with the list-cta.ts file
  &.mobile {
    display: flex;
    flex-direction: column;
    @media (min-width: $lg-min) {
      display: none;
    }

    .list-cta-accordion-heading {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 1rem;
      .heading-title {
        margin-bottom: 0;
      }
    }

    button.collapsible {
      background: none;
      border: none;
      padding: 0;
      text-align: left;
      //margin-bottom: 2rem;
      position: relative;
      h3 {
        margin-bottom: 0;
      }
    }

    span.expand-all {
      display: block;
      font-size: 1rem;
      font-family: $font-family-2;
      position: absolute;
      width: 100%;
      top: 0;
      text-align: right;
      /* &:after {
        content: '\02795'; /* Unicode character for "plus" sign (+) */
      /* font-size: 0.875rem;
        position: absolute;
        top: 0.125rem;
        right: 0;
        margin-left: 5px;
        color: $font-color-16;
      } */

      &.active {
        /* &:after {
          content: '\2796' !important; /* Unicode character for "minus" sign (-) */
        color: $font-color-16;
        /* } */
      }

      /* @media (max-width: $xs-max) {
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        max-height: 0;
      } */
    }

    .list-cta-accordion-content {
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }
  }
}
