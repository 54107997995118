$midnight-main: #1a1d36;
$midnight-light: #4d5f80;
$midnight-medium: #24326a;

$cerulean-main: #006a8e;
$cerulean-light: #6ba4b8;
$cerulean-dark: #074f71;

$lime-main: #9eb356;
$lime-light: #c4d6a4;
$lime-dark: #79863c;
$lime-contrast: #697533;

$incubus-red: #782327;

/* Palette - Grey */

$gray-main: #63656a;
$gray-light: #f0f0ef;
$gray-medium: #b2b2b2;
$gray-dark: #414141;
$gray-nav: #dad9d7;
$md-max: 63.9375rem; //1023px
.search-results-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;
  padding-top: 2.5rem;
  padding-inline: 2rem;
  @media (max-width: $md-max) {
    padding: 0;
    padding-inline: 1rem;
  }

  .container {
    width: 100rem;
    padding: 0;
  }

  .search-results-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    h2 {
      font-size: 2.5rem;
      color: $midnight-main;
      font-weight: bold;
      margin: 0 0 40px 0;

      @media (max-width: $md-max) {
        font-size: 1.75rem;
        margin: 0 0 1.5rem 0;
      }
    }

    p {
      font-size: 1.5rem;
      color: $gray-dark;
      margin: 0 0 3.75rem 0;
    }
  }

  .search-results-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 3.75rem 0;
    gap: 2rem;
    h2 {
      font-size: 4rem;
    }
    @media (max-width: $md-max) {
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 0.0625rem solid $gray-nav;
      margin: 0 0 1.875rem 0;
      h2 {
        font-size: 2rem;
      }
    }
  }

  .search-results-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .input-wrapper {
    width: 100%;
    max-width: calc(50% - 6.5rem);
    margin: 0;
    border: 0.125rem solid $midnight-main;

    @media (max-width: $md-max) {
      max-width: calc(100% - 0.25rem);
      margin: 0 0 1.5rem 0;
    }

    input {
      width: calc(100% - 5.7rem);
      height: 3.625rem;
      font-size: 1.125rem;
      color: $midnight-main;
      padding: 0 1.75rem;
      border: none;
    }
    input:focus {
      outline: none;
    }
    button {
      width: 3.375rem;
      height: 3.625rem;
      background: transparent;
      border: none;

      @media (max-width: $md-max) {
        width: 3rem;
      }

      i {
        font-size: 1.5rem;
        color: $lime-dark;
      }

      &.close {
        width: 1.625rem;
        visibility: hidden;

        &.active {
          visibility: visible;
        }

        i {
          color: $midnight-main;
        }
      }
    }
  }

  .counter-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1.5rem 0 1.5rem 0;
    column-gap: 1.4375rem;

    .certified-toggle {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .switch {
        margin-right: 1rem;
      }

      label.title {
        font-size: 1.125rem;
        font-weight: 500;
      }

      .badge {
        font-size: 0.7rem;
        margin-left: 0.5rem;

        .fa-certificate {
          color: $cerulean-main;
        }
      }
    }
    .counter-result-wrapper {
      margin-left: auto;
      margin-top: 0;
      display: flex;
      align-items: center;
      gap: 1rem;
      @media (max-width: $md-max) {
        margin-top: 2.5rem;
        margin-left: 0;
      }
    }
    .counter-sort {
      display: flex;
      font-size: 1.125rem;
      margin-left: auto;

      .counter {
        padding-right: 1.4375rem;
        border-right: 0.125rem solid $gray-nav;
      }

      .sort {
        font-weight: normal;

        label {
          font-size: 1.125rem;
          margin: 0;
        }

        #sort-select {
          font-family: Cera Pro, sans-serif;
          font-size: 1.125rem;
          font-weight: 500;
          color: $midnight-main;
          background-color: $white;
          border: none;
          font-weight: bold;
          cursor: pointer;
          option {
            background-color: inherit;
          }
        }
      }
    }

    @media (min-width: $md-min) {
      margin-bottom: 1.5rem;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .search-results-counter {
      font-size: 1.125rem;
      margin: 0 0 0 auto;
      padding-right: 1.4375rem;
      border-right: 0.125rem solid $gray-nav;

      @media (max-width: $md-max) {
        width: auto;
        text-align: left;
      }
    }

    label {
      font-size: 1.125rem;
      margin: 0 0.5rem 0 0;
    }

    select {
      font-family: Cera Pro, sans-serif;
      font-size: 1.125rem;
      color: $midnight-main;
      border: none;
    }
  }

  .search-results-counter {
    font-size: 1.5rem;

    @media (max-width: $md-max) {
      width: 100%;
      text-align: center;
      margin: 0 0 2rem 0;
    }
  }

  .key-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 0 2.9375rem 0;
    border-bottom: 0.125rem solid $gray-nav;
    gap: 2rem;
    overflow: hidden;
    transition: all 0.3s ease;

    @media (max-width: $md-max) {
      height: 20.5625rem;
      border: none;

      &.expanded {
        height: 100%;
      }
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(25% - 4rem);
      height: 6.4375rem;
      font-size: 1.125rem;
      color: $cerulean-main;
      padding: 0 1rem;
      background: $gray-light;
      box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      margin-left: 0;

      &.checked {
        color: white;
        background: $cerulean-main;
      }

      @media (max-width: $md-max) {
        height: 3.875rem;
      }

      @media (min-width: $sm-min) and (max-width: $sm-max) {
        width: calc(50% - 4rem);
      }

      @media (max-width: $md-max) {
        width: 100%;
      }
    }
  }

  .see-more {
    display: none;
    width: 100%;
    font-family: Cera Pro, sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    color: $midnight-main;
    line-height: 1.125rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 2.5rem 0;
    background: transparent;
    border: none;

    @media (max-width: $md-max) {
      display: block;
    }
  }

  .search-results-facets {
    width: 20%;
    margin: 0 5.625rem 0 0;

    @media (max-width: $md-max) {
      position: fixed;
      top: 0;
      right: -100%;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      background: white;
      overflow: auto;
      overscroll-behavior: contain;
      z-index: 900;
      transition: all 0.3s ease;
      padding-left: 1.4375rem;
      padding-right: 1.4375rem;
      &.open {
        right: 0;
      }
    }

    .input-wrapper {
      max-width: calc(100% - 0.25rem);

      input {
        width: calc(100% - 5.75rem);
        padding: 0 1.5rem;
      }
    }

    .accordion {
      width: 100%;
      max-width: inherit;

      @media (max-width: $md-max) {
        margin: 0 0 2rem 0;
      }

      .accordion-item {
        background: transparent;
        color: $midnight-main;
        margin: 0;

        &:first-child {
          .accordion-item-header {
            border-top: 0.0625rem solid $gray-nav;
          }
        }
      }

      .accordion-item-header {
        font-size: 1.125rem;
        color: $midnight-main;
        text-transform: uppercase;
        line-height: normal;
        background: transparent;
        border-bottom: 0.0625rem solid $gray-nav;
        padding: 0.4375rem 3rem 0.4375rem 0;

        @media (max-width: $md-max) {
          font-size: 1.125rem;
          padding: 0.5rem 0;
        }

        cursor: pointer;

        &::after {
          font-family: 'Font Awesome 6 Free';
          font-size: 1rem;
          position: absolute;
          right: 1rem;
          content: '\f078';

          @media (max-width: $md-max) {
            font-size: 1rem;
          }
        }

        &.active {
          &::after {
            content: '\f077';
          }
        }
      }

      .accordion-item-body-content {
        font-size: 1.125rem;
        padding: 1.4375rem 0;
        border-bottom: 0.0625rem solid $gray-nav;

        @media (max-width: $md-max) {
          padding: 1.4375rem 0;
        }
      }
    }

    .checkbox {
      margin: 0 0 1.5rem 0;

      &:last-child {
        margin: 0;
      }

      input[type='checkbox'] {
        accent-color: $midnight-main;
        margin: 0 1rem 0 0;
      }
    }
  }

  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      height: 3rem;
      font-size: 1.125rem;
      color: $midnight-main;
      line-height: normal;
      padding: 0 0.25rem 0 1rem;
      background: $gray-light;
      margin-left: 0;

      &:last-child {
        font-size: 1rem;
        text-transform: uppercase;
        padding: 0;
        background: transparent;
      }

      button {
        color: $midnight-main;
        border: none;
        background: transparent;
        cursor: pointer;

        &.close {
          padding: 0.75rem;
          transform: translateY(2px);
        }

        &.reset {
          font-size: 1.125rem;
          text-transform: uppercase;

          i {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .search-results {
    width: calc(80% - 5.625rem);
    min-height: 12rem;
    @media (max-width: $md-max) {
      width: 100%;
    }

    > ul,
    .search-results-items > ul {
      display: flex;
      flex-wrap: wrap;
      margin-right: -2rem;
      margin: 0 0 3rem 0;
      padding: 0;
      gap: 2rem;

      > li {
        list-style: none;
        width: 100%;
        margin-left: 0;

        @media (min-width: $md-min) {
          width: calc(50% - 1.5rem);
        }

        @media (min-width: $lg-min) {
          width: calc(33.33333% - 1.5rem);
        }

        > a {
          text-decoration: none;
          color: inherit;
        }

        .card.stacked {
          .heading {
            h3 {
              font-size: 1.375rem;
            }
          }
        }
      }
    }

    &.trial-demo-results {
      > ul,
      .search-results-items > ul {
        margin: 1.9375rem 0 3.5rem 0;

        @media (max-width: $md-max) {
          margin: 0 0 3rem 0;
          padding: 0 0.125rem;
        }
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    width: 100%;

    > ul {
      display: flex;
      padding: 0;

      li {
        list-style: none;
        margin-left: 0;

        &.active {
          a {
            background: $lime-main;
          }
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.6875rem;
          height: 1.6875rem;
          font-size: 1.25rem;
          color: $midnight-main;
          text-decoration: none;
          margin: 0.5rem;

          i {
            font-size: 1.25rem;
            color: $midnight-main;
          }
        }
      }
    }
  }

  .hide-filters {
    position: relative;
    display: none;
    width: 100%;
    font-size: 1.5rem;
    color: $midnight-medium;
    font-weight: bold;
    border: none;
    background: transparent;
    padding: 3.125rem 2rem 2rem 2rem;

    @media (max-width: $md-max) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    i {
      color: $midnight-main;
    }

    > i {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    span {
      i {
        margin-right: 1.5rem;
      }
    }
  }

  .show-filters {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    font-size: 1.5rem;
    color: $midnight-medium;
    font-weight: bold;
    padding: 1.625rem;
    background: $gray-light;
    border: none;

    @media (max-width: $md-max) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    i {
      color: $midnight-main;
      margin-right: 1.5rem;
    }
  }

  .pagination {
    @media (max-width: $md-max) {
      margin-bottom: 6.25rem;
    }
  }

  .accordion {
    width: 90%;
    max-width: 62.5rem;
    margin: 2rem auto;
    padding: 0;
    @media (max-width: $md-max) {
      margin-bottom: 2rem;
    }
    .accordion-item {
      background: $gray-light;
      color: $midnight-main;
      margin: 1rem 0;
    }

    .accordion-item-header {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 3.5rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: white;
      line-height: 2rem;
      background: $cerulean-dark;
      padding: 0.5rem 3rem 0.5rem 1rem;

      @media (max-width: $md-max) {
        font-size: 1.125rem;
        padding: 0.5rem 1rem;
      }

      cursor: pointer;

      .heading-title {
        color: white;
        margin-bottom: 0;
      }

      &::after {
        font-size: 1.5rem;
        position: absolute;
        right: 1rem;
        content: '\002B';

        @media (max-width: $md-max) {
          font-size: 1rem;
        }
      }

      &.active {
        &::after {
          content: '\2212';
        }
      }
    }

    .accordion-item-body {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    .accordion-item-body-content {
      font-size: 1rem;
      padding: 2rem;

      @media (max-width: $md-max) {
        padding: 1rem 0.5rem;
      }
    }
  }
}

.search-results {
    .search-results-items {
        .content-tile__eyebrow-wrapper {
            .content-tile__eyebrow-text {
                color: #000;
            }

            .content-tile__eyebrow {
                background: #1cb8b8;
            }
        }
    }
}