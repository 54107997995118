.dynamic-content-block {
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: $md-min) {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  &.inline-padding-off {
    padding-right: 0;
    padding-left: 0;
  }

  &--sidebar {
    padding: 2rem;

    .dynamic-content-block__heading {
      margin-bottom: 1rem;
    }

    .flag {
      display: none;
    }

    .tags-wrapper {
      margin-top: 1rem;
    }

    .description,
    .description > * {
      font-family: $font-family-2;
      color: $font-color-3;
      margin-top: 1rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  &__header-cta {
    display: none;

    @media (min-width: $md-min) {
      display: block;
    }
  }

  &__hr {
    display: block;
    width: 100%;
    border-top: 1px solid $font-color-2;
    margin-bottom: 2.5rem;
  }

  &__content-grid {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: $md-min) {
      flex-direction: row;
    }

    .dynamic-content-block__description {
      flex-basis: 35%;

      p {
        color: $font-color-9;
      }
    }

    .dynamic-content-block__tiles {
      flex-basis: 65%;
    }
  }

  &__adjacent-cards {
    margin-top: 3rem;
  }

  &__tiles,
  &__adjacent-cards {
    > ul {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 3rem;

      @media (min-width: $md-min) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: max-content;
      }

      @media (min-width: $lg-min) {
        grid-template-columns: repeat(3, 1fr);
      }

      > li {
        width: 100%;
        list-style: none;
        margin-left: 0;

        .content-tile {
          height: 100%;
        }
      }
    }

    &--grid {
      > ul {
        @media (min-width: $md-min) {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-auto-rows: max-content;
        }

        @media (min-width: $lg-min) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  &__sidebar-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__footer-cta {
    display: block;

    @media (min-width: $md-min) {
      display: none;
    }
  }
}
