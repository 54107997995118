header {
    &.page__header {
        &:has(.main-navigation.disable-sticky-mode) {
            position: relative;
        }
        top: 0;
        left: 0;
        width: 100vw;
        background-color: #fff;
        transition: transform 0.25s ease-in-out;
    }
    position: relative;
    z-index: 99;
}

$main-navigation-height: 60px;

.main-navigation {
    position: relative;
    flex-direction: column;
    border-bottom: 1px solid $border-color-2;
    z-index: 100;

    hr {
        margin: 0 0 1rem 0;
        background-color: $border-color-3;

        @media (min-width: $lg-min) {
            width: 100%;
            margin: 2rem 0;
        }
    }

    .call-to-action {
        .call-to-action-content {
            gap: 0.25rem;

            .call-to-action-content-sub {
                gap: 0.25rem;
            }

            .cta-subtitle {
                font-size: 0.75rem;
            }
        }

        .cta-text {
            font-size: 0.875rem;
            text-decoration: underline;
        }

        @media (min-width: $lg-min) {
            .cta-text {
                text-decoration: none;
            }
        }
    }

    &.search-bar-expanded .header-row-mobile {
        justify-content: center;
    }

    .header-row-mobile {
        position: relative;
        display: flex;
        height: $main-navigation-height;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.4375rem;
        background-color: $background-color-16;
        width: 100%;

        .close-menu {
            position: relative;
            width: 1.875rem;
            background: transparent;
            border: none;
            cursor: pointer;
            color: $font-color-3;

            i {
                font-size: 1.25rem;

                &.fa-xmark {
                    display: none;
                }
            }

            &.active {
                i {
                    &.fa-xmark {
                        display: block;
                    }

                    &.fa-bars {
                        display: none;
                    }
                }
            }

            + div {
                //Opti injected div
                .call-to-action {
                    a {
                        margin: 0;
                        padding: 0;

                        .call-to-action-image {
                            width: unset;
                        }
                    }
                }
            }
        }

        a {
            margin: 0 auto;
        }

        img {
            max-width: 9.375rem;
        }

        @media (min-width: $lg-min) {
            display: none;
        }
    }

    .header-row-desktop {
        display: none;
        align-items: center;
        padding: 0 1.875rem;
        gap: 20px;

        &__left,
        &__right {
            flex: 0 1 50%;
        }

        &__left .call-to-action-image {
            width: 100%;
            height: auto;

            img,
            svg {
                width: 100%;
                max-width: 100%;
            }
        }

        &__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
        }

        img {
            margin-right: auto;
        }

        .contained {
            margin-left: 2rem;
        }

        .resp-tabs-list {
            & + div {
                display: flex;
            }
        }

        @media (min-width: $lg-min) {
            display: flex;
        }
    }

    .padded-retainer,
    .section-block__main-content-area.container {
        @media (min-width: $lg-min) {
            padding: 2.6875rem 1.875rem;
        }
    }

    ul.resp-tabs-list,
    p {
        margin: 0;
        padding: 0;
    }

    .header-row-desktop__left {
        .width-small {
            width: 80% !important;
        }
    }

    ul.resp-tabs-list.hor-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 100%;
        max-width: 45rem;
        justify-content: space-between;
    }

    .resp-tabs-list .resp-tab-item.hor-1 {
        display: inline-block;
        font-size: 1rem;
        color: $font-color-3;
        font-weight: bold;
        padding: 1.75rem 0;
        background: transparent;
        list-style: none;
        cursor: pointer;
        border-bottom: 0.25rem solid transparent;
        transition: border-bottom 0.3s ease-in;
        transform-origin: left;

        &:hover,
        &:active {
            border-bottom: 0.25rem solid $border-color-2;
            transition: border-bottom 0.3s ease-in;
        }

        &.padding-small {
            padding: 2rem 0;
        }

        @media screen and (min-width: $xl-min) {
            padding: 3.25rem 0 3rem;
        }
    }

    .resp-tabs-container {
        padding: 0;
        background-color: $font-color-16;
        // hide other tabs if one is open on mobile
        &.has-active-tab-child > .resp-accordion:not(.resp-tab-active) {
            display: none;
        }

        &.hor-1 {
            position: absolute;
            top: $main-navigation-height;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            transition: all 0.3s ease;
            height: 0;
            max-height: 0;
            background-color: $background-color-16;
            box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.16);

            @media (min-width: $lg-min) {
                height: auto;
                top: 100%;
                bottom: auto;
                max-height: none;
            }

            &.active {
                height: calc(100dvh - #{$main-navigation-height}); //minus .header-row-mobile
                max-height: 100vh;
                overflow-y: auto;
            }
        }
    }

    h2.resp-accordion {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        color: $font-color-1;
        margin: 0 1.25rem;
        padding: 1.25rem 0;
        background: white;
        border-bottom: 0.0625rem solid $border-color-1;
        cursor: pointer;
        flex: 1;

        &.hor-1 {
            &::after {
                position: absolute;
                right: 0;
                font-size: 1.625rem;
                font-weight: lighter;
                color: $font-color-3;
                content: '\203A';
                transform: rotate(0deg);
            }

            &.resp-tab-active {
                border-bottom: none;
                justify-content: center;

                &::after {
                    transform: rotate(180deg);
                    right: unset;
                    left: 0;
                    font-size: 1.825rem;
                    color: $font-color-3;
                }
            }
        }

        &[class*='ver-'] {
            margin: 0;
            padding: 1.25rem;

            &::after {
                position: absolute;
                top: 1rem;
                right: 1.25rem;
                font-size: 1.125rem;
                font-weight: lighter;
                color: $font-color-3;
                content: '+';
            }

            &.resp-tab-active {
                &::after {
                    content: '-';
                }
            }
        }
    }

    .resp-tab-content {
        display: none;

        & .list-cta.mobile {
            display: none;
        }

        & > .Grid--item.col__sm-12.col__lg-9 {
            padding: 0;

            @media screen and (min-width: $lg-min) {
                padding: 3.5rem;
            }

            .section-block__container {
                padding-bottom: 0;

                & .Grid--container {
                    & .Grid--container {
                        & .Grid--item {
                            padding-top: 0;

                            @media screen and (min-width: $lg-min) {
                                flex-basis: 33.33333333333%;
                                max-width: 33.33333333333%;
                                padding-top: 1rem;
                            }
                        }
                    }
                }
            }
        }

        & > div:nth-child(1) {
            .section-block__container {
                padding: 0;

                & .Grid--container {
                    & .Grid--container {
                        & .Grid--item {
                            padding-top: 0;

                            @media (min-width: $lg-min) {
                                padding-top: 1rem;
                            }
                        }
                    }
                }

                @media (min-width: $lg-min) {
                    padding: 0;
                }
            }
        }
        // menu sidebar styling
        & > div:nth-child(2) {
            padding: 1.25rem;
            background-color: $font-color-4;
            margin: 1.5rem;

            .Grid--container {
                .Grid--item {
                    flex-basis: 100%;
                }
            }

            @media screen and (min-width: $lg-min) {
                padding: 2rem;
                flex-basis: 50%;
                margin: 0;
            }
        }
    }

    .resp-tab-active {
        position: relative;
        z-index: 1;
        border-bottom: 0.25rem solid $border-color-4;
    }

    .resp-content-active,
    .resp-accordion-active {
        display: block;
    }
    /*-----------Vertical tabs-----------*/
    .resp-vtabs ul.resp-tabs-list {
        display: none;
        flex-direction: column;
        padding: 1rem;
        background: $background-color-1;
        margin-top: 0;

        & .call-to-action {
            margin-top: 2rem;
            width: 100%;
            max-width: 500px;
        }

        @media (min-width: $lg-min) {
            display: flex;
        }
    }

    .resp-vtabs .resp-tabs-list li {
        position: relative;
        display: block;
        font-size: 1rem;
        color: white;
        padding: 1rem 3.125rem 1rem 2rem;
        margin: 0 0 0.25rem;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 1.625rem;
            left: 0.9375rem;
            width: 0.3125rem;
            height: 0.3125rem;
            border: 0.0625rem solid white;
            content: '';
        }

        &::after {
            position: absolute;
            top: 0.375rem;
            right: 2.125rem;
            font-size: 1.625rem;
            font-weight: lighter;
            color: white;
            content: '\203A';
        }

        p {
            font-size: 0.75rem;
            font-weight: lighter;
            color: $background-color-1;
        }
    }

    .resp-vtabs .resp-tabs-container {
        padding: 0;
        background-color: white;
        border: none;
        height: 100%;
        border-radius: 0;
    }

    .resp-vtabs .resp-tab-content {
        border: none;
        word-wrap: break-word;
        @extend .padded-retainer;
        background-color: $background-color-1;

        & > * {
            &:nth-child(1) {
                padding: 1.5rem;
            }
        }

        @media screen and (min-width: $md-min) {
            padding: 0;
            height: 100%;

            & > * {
                &:nth-child(1) {
                    padding: 2rem;
                }
            }

            & .Grid--container {
                hr {
                    margin-block: 2.5rem;
                }
            }
        }

        @media screen and (min-width: $xl-min) {
            & > * {
                &:nth-child(1) {
                    padding: 2rem 3rem;
                }
            }

            & .Grid--container {
                & .Grid--item {
                    flex-basis: 41.66666666666667%;
                }
            }
        }
    }

    .resp-vtabs li.resp-tab-active {
        color: $font-color-1;
        background: #3c3f54;
        border: none;

        &::before {
            position: absolute;
            top: 1.625rem;
            left: 0.9375rem;
            width: 0.3125rem;
            height: 0.3125rem;
            background: $background-color-1;
            border: 0.0625rem solid $border-color-1;
            content: '';
        }

        &::after {
            color: $font-color-1;
        }

        p {
            color: white;
        }
    }
    /*-----------Accordion styles-----------*/
    .resp-easy-accordion h2.resp-accordion {
        display: block;
    }

    .resp-jfit {
        width: 100%;
        margin: 0;
    }

    .resp-tab-content-active {
        display: block;
        width: 100%;
    }
    /*Here your can change the breakpoint to set the accordion, when screen resolution changed*/
    @media (min-width: $lg-min) {
        ul.resp-tabs-list,
        ul.resp-tabs-list.hor-1 {
            display: flex;
        }

        h2.resp-accordion {
            display: none;

            p {
                display: none;
            }
        }

        .resp-vtabs .resp-tabs-container {
            border: none;
            width: 100%;
            min-height: 6.25rem;
        }

        .resp-accordion-closed {
            display: none;
        }
    }

    .line-heading {
        position: relative;
        font-size: 0.75rem;
        color: $border-color-1;
        margin: 0 0 1.5rem 0;
        padding-left: 3rem;

        .heading-title {
            font-size: inherit;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0.5rem;
            display: block;
            width: 2.5rem;
            height: 0.125rem;
            background: $background-color-3;
            content: '';
        }
    }

    .support-cta {
        display: flex;
        gap: 1.5rem;
    }
}

.hide-overflow {
  overflow: hidden;
}
