.event-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__image-wrapper {
    margin-bottom: 0.9375rem;

    img {
      max-width: 100%;
    }
  }
  &__heading {
    .heading-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  &__description,
  &__description > * {
    font-size: 1rem;
  }

  &__date-time {
    color: $font-color-7;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__cta {
    margin-top: 2.5rem;
  }

  @media (min-width: $desktop-break-min) {
    &__heading {
      .heading-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }

    &__description,
    &__description > * {
      font-size: 1.25rem;
    }
  }
}
