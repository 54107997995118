$search-icon-width--desktop: 1.3rem;

.main-navigation {
  &.search-bar-expanded {
    .resp-tabs-list.hor-1 {
      visibility: hidden;
    }

    .resp-tabs-container.hor-1 {
      display: none;
    }

    .support-cta {
      visibility: hidden;
    }

    .header-row-mobile {
      .close-menu {
        display: none;
      }
    }

    .close-searchbar-modal {
      display: block;

      @media (min-width: $lg-min) {
        display: none;
      }
    }

    .close-search {
      display: block;

      i {
        border-right: 1px solid $font-color-2;
      }
    }
  }

  .close-searchbar-modal {
    position: absolute;
    right: 1.75rem;
    width: 1.875rem;
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
    color: $font-color-3;

    i {
      font-size: $search-icon-width--desktop;
    }
  }
}

.close-search {
  position: absolute;
  right: calc(100% + 0.5rem);
  top: 50%;
  display: none;
  place-items: center;
  translate: 0 -50%;
  padding-block: 0.4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $font-color-3;

  i {
    display: block;
    padding: 0.1rem 0.5rem;
    font-size: $search-icon-width--desktop;
  }
}

.typeahead {
  background-color: $font-color-2;
  border: none;
}

.typeahead a {
  padding: 0.5rem 1.875rem;
  color: $font-color-3;
  line-height: normal;
}

.typeahead .active > a {
  background-color: transparent;
  text-decoration: underline;
  color: $font-color-3;
}

.search-bar {
  position: relative;
  margin-right: 0;

  .search-field {
    display: flex;
    flex-direction: row;

    form {
      display: flex;
      width: 100%;
    }

    input {
      position: absolute;
      right: calc(100% - #{$search-icon-width--desktop});
      bottom: 0;
      box-sizing: border-box;
      flex-direction: column;
      text-align: left;
      width: 0;
      margin: 0;
      border: 0;
      font-size: 1rem;
      font-family: $font-family-2;
      line-height: 1.63;
      transition: width 0.3s ease;

      &::placeholder {
        color: $font-color-9;
      }
    }

    .search-label {
      display: inline;
      margin: 0 0.75rem;
      font-size: 1rem;
      font-family: $font-family-2;
      text-transform: uppercase;
      color: $font-color-3;
    }
  }

  &.expanded {
    .search-field {
      input {
        width: min(50vw, 60rem);
        border-bottom: 3px solid $font-color-5;

        &:focus {
          outline: none;
          border-width: 4px; // needs some sort of focus indicator
          cursor: text;
        }
      }

      .search-label {
        display: none;
      }
    }
  }

  .search-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: none;
    padding: 0.625rem 0;
    border: none;
    cursor: pointer;

    .fa-search {
      width: $search-icon-width--desktop;
      height: auto;
      font-size: $search-icon-width--desktop;
      color: $font-color-6;
    }

    #search-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: $font-family-2 Bold;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
      color: $font-color-3;
      text-transform: uppercase;
    }
  }

  @media (max-width: $lg-max) {
    width: 1.5rem;

    &.expanded {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: calc(100dvh - 50px);
      margin-top: 3.25rem;
      max-height: 100vh;
      overflow-y: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      background-color: $font-color-16;
      z-index: 100;

      .search-field {
        width: 100%;
        height: 2rem;
        padding: 0 2rem;
        margin-top: 0.8rem;

        .search-button {
          margin-left: -1.5rem;
          padding-block: 0.5rem 1rem;
        }

        input {
          position: static;
          flex: 1 1 100%;
        }
      }
    }

    .search-field {
      margin: 0;

      .search-label {
        display: none;
      }
    }
  }
}
