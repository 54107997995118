﻿$font-family_1:  "Cera Pro", "Helvetica Neue", Arial, sans-serif;
$font-family_2: "Cera Basic", "Helvetica Neue", Arial, sans-serif;
$font-family_3: "Instrument Sans", serif;

$font-smallest: 1rem;
$font-smaller: 1.25rem;
$font-small: 1.50rem;
$font-medium: 1.75rem;
$font-large: 2rem;
$font-larger: 3rem;
$font-largest: 4rem;

$line-height-largest: 6rem;
$line-height-larger: 5rem;
$line-height-large: 4.5rem;
$line-height-medium: 4rem;
$line-height-small: 3.5rem;
$line-height-smaller: 3rem;
$line-height-smallest: 2.5rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

.font-light {
    font-weight: $font-weight-light;
}

.font-normal {
    font-weight: $font-weight-normal;
}

.font-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.font-bold {
    font-weight: $font-weight-bold;
}

.transform-upper {
    text-transform: uppercase;
}

.transform-lower {
    text-transform: lowercase;
}

.transform-capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.hover-underline {
    text-decoration: underline;
}

.focus-underline {
    text-decoration: underline;
}


.heading-title {
    font-family: $font-family-2;
}

.subheading {
    font-family: $font-family-2;
}

.description {
    font-family: $font-family-1;
}

a {
     font-family: $font-family-1;
}