.quote {
  width: 100%;
  padding: 3rem 1.5rem;
  position: relative;
  @media screen and (min-width: $lg-min) {
    padding: 7.5rem 10rem;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: $lg-min) {
      margin: 0 auto;
      max-width: 75vw;
      max-width: 100rem;
      margin: 0 auto;
    }
  }
  &__background-pattern {
    position: absolute;
    inset: 0;
    width: 100%;
    img {
      max-height: 100%;
      width: 100%;
    }
    @media screen and (min-width: $lg-min) {
      img {
        max-width: 100%;
      }
    }
  }
  .quote-text {
    position: relative;
    text-align: left;
    @media screen and (min-width: $lg-min) {
      margin-bottom: 2.5rem;
    }
    i.fa-quote-left {
      text-align: left;
      display: block;
      font-size: 1.5rem;
      left: 0;
      top: 0;
      position: relative;
      color: $font-color-5;
      @media (min-width: $lg-min) {
        font-size: 4rem;
        left: -2rem;
        top: 0rem;
        display: inline-block;
        position: absolute;
      }
    }
    i.fa-quote-right {
      text-align: right;
      display: block;
      font-size: 1.5rem;
      height: auto;
      right: 0;
      bottom: 0;
      position: relative;
      color: $font-color-5;
      margin-left: auto;
      @media (min-width: $lg-min) {
        font-size: 4rem;
        bottom: 0rem;
        right: -1rem;
        position: absolute;
        display: block;
        margin: 0;
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: inherit;
      font-size: 2.125rem;
      font-weight: 300;
      margin-bottom: 0rem;
      margin: 0;
      @media screen and (min-width: $lg-min) {
        font-size: 3.4375rem;
        margin-inline: 6rem;
        line-height: 1.4;
      }
    }
  }

  .quotee {
    display: flex;
    flex-direction: row;
    @media screen and (min-width: $lg-min) {
      margin-inline: 6rem;
      margin-block-start: -2rem;
    }
    .avatar {
      position: relative;
      margin: 0 1rem 0 3rem;

      img {
        border-radius: 50%;
        width: 3.5rem;
        height: auto;
      }
      &::before {
        content: '---------';
        color: $font-color-8;
        position: absolute;
        top: 0;
        left: -3rem;
      }
      @media (min-width: $lg-min) {
        width: 4.5rem;
      }
    }
    .byline {
      flex-direction: column;
      .name-text {
        font-size: 1.15rem;
        font-weight: 600;
        color: inherit;
      }
      .title-text {
        font-size: 1.15rem;
        font-weight: 400;
        color: inherit;
      }
      @media screen and (min-width: $lg-min) {
        .name-text,
        .title-text {
          font-size: clamp(1.15rem, 1.5vw, 1.875rem);
          font-weight: normal;
        }
      }
    }
  }

  &.quoteMidnight {
    .quote-text {
      h3 {
        color: white;
      }
    }

    .byline {
      p {
        color: white;
      }
    }
  }
}

.carousel {
  .quote {
    padding: 3rem 0 0;
    @media screen and (min-width: $lg-min) {
      padding: 7.5rem 10rem 0 10rem;
    }
  }
}
