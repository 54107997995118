.accordion {
    margin: 2rem 1.5rem;

    @media screen and (min-width: $lg-min) {
        margin: 2rem auto;
    }

    &__heading {
        text-align: center;
    }

    &__description,
    &__description > p {
        font-size: 1rem;
        font-weight: normal;
        margin: 0 0 2rem 0;
        text-align: center;

        @media only screen and (min-width: $lg-min) {
            font-size: 1.375rem;
            margin: 0 0 3rem 0;
        }
    }

    .accordion__item {
        color: $font-color-3;
        margin: 1rem 0;

        &.active {
            .accordion__item_header {
                background-color: $background-color-2;
                transition: background-color 0.2s ease-out;

                .heading-title {
                    color: $font-color-3;
                }

                &::after {
                    content: '\2212';
                    color: $font-color-3;
                }
            }

            .accordion__item_body {
                height: fit-content;
                transition: height 0.2s ease-out;
            }

            .accordion__item_body-content {
                visibility: visible;
                transform: translateY(0);
                transition: visibility 0.2s ease-out, transform 0.2s ease-out;
            }
        }
    }

    .accordion__item_header {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 3.5rem;
        font-weight: bold;
        line-height: 2rem;
        background-color: $background-color-2;
        transition: background-color 0.2s ease-out;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 8px;

        @media (min-width: $md-min) {
            padding: 0.5rem 3rem 0.5rem 1rem;
        }

        .heading-title {
            font-size: 1.125rem;
            margin-bottom: 0;

            @media (min-width: $lg-min) {
                font-size: 1.5rem;
            }
        }

        &::after {
            position: absolute;
            right: 1rem;
            color: $font-color-3;
            content: '\002B';
            font-size: 1.5rem;
            font-weight: 400;

            @media (min-width: $lg-min) {
                font-size: 2rem;
            }
        }
    }
}

.accordion__item_body {
  height: 0;
  overflow: hidden;
  background-color: $background-color-16;
  transition: all 0.2s ease-out;
}

.accordion__item_body-content {
  font-size: 1rem;
  padding: 1.5rem;
  visibility: hidden;
  transform: translateY(-20%);
  transition: visibility 0.2s ease-out, transform 0.2s ease-out;
  line-height: 2;
  @media (min-width: $lg-min) {
    padding: 2rem;
  }
}
