.herocarousel {
  padding: 0;
  padding-bottom: 3rem;

  @media (min-width: $md-min) {
    padding-bottom: 0;
  }

  .swiper-button-prev {
    left: 4.5rem;
  }

  .swiper-button-next {
    right: 4.5rem;
  }

  .swiper-pagination {
    display: block;

    @media (min-width: $md-min) {
      display: none;
    }
  }
}

.herocarouselslide {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0;

  @media (min-width: $md-max) {
    flex-direction: row;
    gap: 2rem;
  }

  @media (min-width: $md-min) {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  &__content {
    flex-basis: 50%;
    padding: 1.5rem 1.5rem 1rem 1.5rem;

    @media (min-width: $md-min) {
      padding: 3.75rem 0 2rem 0;
    }
  }

  &__eyebrow-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__eyebrow {
    display: inline-block;
    width: 2.5rem;
    height: 2px;
    font-weight: 500;
    background-color: $font-color-6;
    margin-right: 0.313rem;
  }

  .heading-title {
    font-family: $font-family-1;
    font-size: 2rem;
    font-weight: normal;
    margin-bottom: 2rem;
    color: $font-color-3;
    display: block;

    @media (min-width: $md-min) {
      font-size: 4rem;
    }
  }

  &__description {
    margin-block: 0 $margin-sm2;

    & *,
    > * {
      font-size: 1.25rem;

      @media (min-width: $md-min) {
        font-size: 1.5rem;
      }
    }
  }

  &__cta-wrapper {
    margin-top: 1.5rem;

    @media (min-width: $md-min) {
      margin-top: 3rem;
    }
  }

  &__image-wrapper {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
