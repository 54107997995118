$lg-min: 64rem; //1024px
$gray-light: #f0f0ef;
.blog {
    &__wrapper {
        padding: 1.5rem 0;

        @media (min-width: $lg-min) {
            padding: 2rem;
        }
    }

    &__inner {
        gap: 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100rem;
        margin: 0 auto;

        @media (min-width: $lg-min) {
            flex-direction: row;
        }
    }

    &__main {
        padding: 1rem 1.5rem 0;
        flex: 1 1 75%;

        @media (min-width: $lg-min) {
            .quote {
                padding: 2rem 2rem 0;
                margin-bottom: 3rem;
            }
        }
    }

    &__aside {
        flex: 0 1 25%;

        &-content {
            &-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.25rem;
                padding: 1.5rem;

                @media (min-width: $lg-min) {
                    padding: 1.5rem;
                }

                .section-block__container {
                    
                }
                
            }

            &-item {
                background-color: $gray-light;
                padding: 1.5rem;
                width: 100%;

                .section-block__container {
                    padding: 0 !important;
                }

                .call-to-action {
                    &.disable-padding {
                        padding-block: 0;
                    }

                    .cta-text {
                        font-size: 1rem;
                    }
                }

                & .section-block {
                    .section-block__title {
                        text-align: left;

                        .heading-title {
                            text-align: left;
                            font-size: 1.5rem;
                        }
                    }

                    .Grid--container {
                        gap: 1rem;
                    }
                }

                & .list-cta {
                    margin: 0;
                    flex-direction: column;
                    align-items: flex-start;

                    & .call-to-action {
                        &.disable-padding {
                            a {
                                padding-top: 0;
                            }
                        }

                        & .cta-text {
                            text-transform: capitalize;
                            font-size: 1rem;
                        }

                        & .call-to-action-iconbox {
                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
