$gridSpacing_1: 0.5rem; //8px
$gridSpacing_2: 1rem; //16px
$gridSpacing_3: 1.5rem; //24px
$gridSpacing_4: 2rem; //32px
$gridSpacing_5: 2.5rem; //40px
$gridSpacing_6: 3rem; //48px
$gridSpacing_7: 3.5rem; //56px
$gridSpacing_8: 4rem; //64px
$gridSpacing_9: 4.5rem; //72px
$gridSpacing_10: 5rem; //80px

$direction_row: row;
$direction_col: column;

$gridGap: 1rem;

$direction_row: row;
$direction_col: column;


.container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0 auto;

    &.grid-gutter {
        gap: $gridGap;
        padding: 0;
    }

    .row {
        display: flex;
        width: 100%;
        gap: $gridGap;
        flex-wrap: wrap;
    }

    .container {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}

.container-centered {
    width: 100%;
    max-width: 100rem; // 1600px
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.col-2 {
    flex-basis: 16.66666666666667%;
    max-width: 16.66666666666667%;
}

.col-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-4 {
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
}

.col-5 {
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
}

.col-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-7 {
    flex-basis: 58.333333333%;
    max-width: 58.333333333%;
}

.col-8 {
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
}

.col-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-10 {
    flex-basis: 83.33333333333333%;
    max-width: 83.33333333333333%;
}

.col-11 {
    flex-basis: 91.66666666667%;
    max-width: 91.66666666667%;
}

.col-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    display: flex;

    footer & {
        display: block;
    }

    @media (max-width: $xs-max) {
        flex: 100% 0 0;
    }
}

.hide-below-md {
    @media (max-width: $md-max) {
        display: none;
    }
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.Grid--container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: $md-min) {
        flex-direction: row;
    }
    /** --- ALIGN --- */

    &.Grid--align__start {
        align-items: flex-start;
    }

    &.Grid--align__end {
        align-items: flex-end;
    }

    &.Grid--align__center {
        align-items: center;
    }

    &.Grid--align__stretch {
        align-items: space-between;
    }

    &.Grid--align__start-md {
        @media only screen and (min-width: $md-min) {
            align-items: flex-start;
        }
    }

    &.Grid--align__end-md {
        @media only screen and (min-width: $md-min) {
            align-items: flex-end;
        }
    }

    &.Grid--align__center-md {
        @media only screen and (min-width: $md-min) {
            align-items: center;
        }
    }

    &.Grid--align__stretch-md {
        @media only screen and (min-width: $md-min) {
            align-items: space-between;
        }
    }
    /** --- END ALIGN --- */
    /** --- JUSTIFY --- */

    &.Grid--justify__start {
        justify-content: flex-start;
    }

    &.Grid--justify__end {
        justify-content: flex-end;
    }

    &.Grid--justify__center {
        justify-content: center;
    }

    &.Grid--justify__spaceBetween {
        justify-content: space-between;
    }

    &.Grid--justify__spaceAround {
        justify-content: space-around;
    }

    &.Grid--justify__spaceEvenly {
        justify-content: space-evenly;
    }

    &.Grid--justify__start-md {
        @media only screen and (min-width: $md-min) {
            justify-content: flex-start;
        }
    }

    &.Grid--justify__end-md {
        @media only screen and (min-width: $md-min) {
            justify-content: flex-end;
        }
    }

    &.Grid--justify__center-md {
        @media only screen and (min-width: $md-min) {
            justify-content: center;
        }
    }

    &.Grid--justify__spaceBetween-md {
        @media only screen and (min-width: $md-min) {
            justify-content: space-between;
        }
    }

    &.Grid--justify__spaceAround-md {
        @media only screen and (min-width: $md-min) {
            justify-content: space-around;
        }
    }

    &.Grid--justify__spaceEvenly-md {
        @media only screen and (min-width: $md-min) {
            justify-content: space-evenly;
        }
    }

    &.Grid--justify__start-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: flex-start;
        }
    }

    &.Grid--justify__end-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: flex-end;
        }
    }

    &.Grid--justify__center-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: center;
        }
    }

    &.Grid--justify__spaceBetween-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: space-between;
        }
    }

    &.Grid--justify__spaceAround-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: space-around;
        }
    }

    &.Grid--justify__spaceEvenly-lg {
        @media only screen and (min-width: $lg-min) {
            justify-content: space-evenly;
        }
    }

    &.Grid--justify__start-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: flex-start;
        }
    }

    &.Grid--justify__end-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: flex-end;
        }
    }

    &.Grid--justify__center-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: center;
        }
    }

    &.Grid--justify__spaceBetween-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: space-between;
        }
    }

    &.Grid--justify__spaceAround-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: space-around;
        }
    }

    &.Grid--justify__spaceEvenly-xl {
        @media only screen and (min-width: $xl-min) {
            justify-content: space-evenly;
        }
    }
    /* --- SPACING --- */

    &.Grid--spacing {
        &__xs-1 {
            margin-top: calc(#{$gridSpacing_1} * -1);
            margin-left: calc(#{$gridSpacing_1} * -1);
            width: calc(100% + #{$gridSpacing_1});

            .Grid--item {
                padding-left: $gridSpacing_1;
                padding-top: $gridSpacing_1;
            }
        }

        &__xs-2 {
            margin-top: calc(#{$gridSpacing_2} * -1);
            margin-left: calc(#{$gridSpacing_2} * -1);
            width: calc(100% + #{$gridSpacing_2});

            & > * {
                &:not(hr) {
                    padding-left: $gridSpacing_2;
                    padding-top: $gridSpacing_2;
                }
            }
        }

        &__xs-3 {
            margin-top: calc(#{$gridSpacing_3} * -1);
            margin-left: calc(#{$gridSpacing_3} * -1);
            width: calc(100% + #{$gridSpacing_3});

            .Grid--item {
                padding-left: $gridSpacing_3;
                padding-top: $gridSpacing_3;
            }
        }

        &__xs-4 {
            margin-top: calc(#{$gridSpacing_4} * -1);
            margin-left: calc(#{$gridSpacing_4} * -1);
            width: calc(100% + #{$gridSpacing_4});

            & > * {
                padding-left: $gridSpacing_4;
                padding-top: $gridSpacing_4;
            }
        }

        &__xs-5 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_5} * -1);
                margin-left: calc(#{$gridSpacing_5} * -1);
                width: calc(100% + #{$gridSpacing_5});

                .Grid--item {
                    padding-left: $gridSpacing_5;
                    padding-top: $gridSpacing_5;
                }
            }
        }

        &__xs-6 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_6} * -1);
                margin-left: calc(#{$gridSpacing_6} * -1);
                width: calc(100% + #{$gridSpacing_6});

                .Grid--item {
                    padding-left: $gridSpacing_6;
                    padding-top: $gridSpacing_6;
                }
            }
        }

        &__xs-7 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_7} * -1);
                margin-left: calc(#{$gridSpacing_7} * -1);
                width: calc(100% + #{$gridSpacing_7});

                .Grid--item {
                    padding-left: $gridSpacing_7;
                    padding-top: $gridSpacing_7;
                }
            }
        }

        &__xs-8 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_8} * -1);
                margin-left: calc(#{$gridSpacing_8} * -1);
                width: calc(100% + #{$gridSpacing_8});

                .Grid--item {
                    padding-left: $gridSpacing_8;
                    padding-top: $gridSpacing_8;
                }
            }
        }

        &__xs-9 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_9} * -1);
                margin-left: calc(#{$gridSpacing_9} * -1);
                width: calc(100% + #{$gridSpacing_9});

                .Grid--item {
                    padding-left: $gridSpacing_9;
                    padding-top: $gridSpacing_9;
                }
            }
        }

        &__xs-10 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_10} * -1);
                margin-left: calc(#{$gridSpacing_10} * -1);
                width: calc(100% + #{$gridSpacing_10});

                .Grid--item {
                    padding-left: $gridSpacing_10;
                    padding-top: $gridSpacing_10;
                }
            }
        }

        &__sm-1 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_1} * -1);
                margin-left: calc(#{$gridSpacing_1} * -1);
                width: calc(100% + #{$gridSpacing_1});

                .Grid--item {
                    padding-left: #{$gridSpacing_1};
                    padding-top: #{$gridSpacing_1};
                }
            }
        }

        &__sm-2 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_2} * -1);
                margin-left: calc(#{$gridSpacing_2} * -1);
                width: calc(100% + #{$gridSpacing_2});

                .Grid--item {
                    padding-left: #{$gridSpacing_2};
                    padding-top: #{$gridSpacing_2};
                }
            }
        }

        &__sm-3 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_3} * -1);
                margin-left: calc(#{$gridSpacing_3} * -1);
                width: calc(100% + #{$gridSpacing_3});

                .Grid--item {
                    padding-left: #{$gridSpacing_3};
                    padding-top: #{$gridSpacing_3};
                }
            }
        }

        &__sm-4 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_4} * -1);
                margin-left: calc(#{$gridSpacing_4} * -1);
                width: calc(100% + #{$gridSpacing_4});

                .Grid--item {
                    padding-left: #{$gridSpacing_4};
                    padding-top: #{$gridSpacing_4};
                }
            }
        }

        &__sm-5 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_5} * -1);
                margin-left: calc(#{$gridSpacing_5} * -1);
                width: calc(100% + #{$gridSpacing_5});

                .Grid--item {
                    padding-left: $gridSpacing_5;
                    padding-top: $gridSpacing_5;
                }
            }
        }

        &__sm-6 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_6} * -1);
                margin-left: calc(#{$gridSpacing_6} * -1);
                width: calc(100% + #{$gridSpacing_6});

                .Grid--item {
                    padding-left: $gridSpacing_6;
                    padding-top: $gridSpacing_6;
                }
            }
        }

        &__sm-7 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_7} * -1);
                margin-left: calc(#{$gridSpacing_7} * -1);
                width: calc(100% + #{$gridSpacing_7});

                .Grid--item {
                    padding-left: $gridSpacing_7;
                    padding-top: $gridSpacing_7;
                }
            }
        }

        &__sm-8 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_8} * -1);
                margin-left: calc(#{$gridSpacing_8} * -1);
                width: calc(100% + #{$gridSpacing_8});

                .Grid--item {
                    padding-left: $gridSpacing_8;
                    padding-top: $gridSpacing_8;
                }
            }
        }

        &__sm-9 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_9} * -1);
                margin-left: calc(#{$gridSpacing_9} * -1);
                width: calc(100% + #{$gridSpacing_9});

                .Grid--item {
                    padding-left: $gridSpacing_9;
                    padding-top: $gridSpacing_9;
                }
            }
        }

        &__sm-10 {
            @media only screen and (min-width: $sm-min) {
                margin-top: calc(#{$gridSpacing_10} * -1);
                margin-left: calc(#{$gridSpacing_10} * -1);
                width: calc(100% + #{$gridSpacing_10});

                .Grid--item {
                    padding-left: $gridSpacing_10;
                    padding-top: $gridSpacing_10;
                }
            }
        }

        &__md-1 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_1} * -1);
                margin-left: calc(#{$gridSpacing_1} * -1);
                width: calc(100% + #{$gridSpacing_1});

                .Grid--item {
                    padding-left: #{$gridSpacing_1};
                    padding-top: #{$gridSpacing_1};
                }
            }
        }

        &__md-2 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_2} * -1);
                margin-left: calc(#{$gridSpacing_2} * -1);
                width: calc(100% + #{$gridSpacing_2});

                .Grid--item {
                    padding-left: #{$gridSpacing_2};
                    padding-top: #{$gridSpacing_2};
                }
            }
        }

        &__md-3 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_3} * -1);
                margin-left: calc(#{$gridSpacing_3} * -1);
                width: calc(100% + #{$gridSpacing_3});

                .Grid--item {
                    padding-left: #{$gridSpacing_3};
                    padding-top: #{$gridSpacing_3};
                }
            }
        }

        &__md-4 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_4} * -1);
                margin-left: calc(#{$gridSpacing_4} * -1);
                width: calc(100% + #{$gridSpacing_4});

                .Grid--item {
                    padding-left: #{$gridSpacing_4};
                    padding-top: #{$gridSpacing_4};
                }
            }
        }

        &__md-5 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_5} * -1);
                margin-left: calc(#{$gridSpacing_5} * -1);
                width: calc(100% + #{$gridSpacing_5});

                .Grid--item {
                    padding-left: $gridSpacing_5;
                    padding-top: $gridSpacing_5;
                }
            }
        }

        &__md-6 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_6} * -1);
                margin-left: calc(#{$gridSpacing_6} * -1);
                width: calc(100% + #{$gridSpacing_6});

                .Grid--item {
                    padding-left: $gridSpacing_6;
                    padding-top: $gridSpacing_6;
                }
            }
        }

        &__md-7 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_7} * -1);
                margin-left: calc(#{$gridSpacing_7} * -1);
                width: calc(100% + #{$gridSpacing_7});

                .Grid--item {
                    padding-left: $gridSpacing_7;
                    padding-top: $gridSpacing_7;
                }
            }
        }

        &__md-8 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_8} * -1);
                margin-left: calc(#{$gridSpacing_8} * -1);
                width: calc(100% + #{$gridSpacing_8});

                .Grid--item {
                    padding-left: $gridSpacing_8;
                    padding-top: $gridSpacing_8;
                }
            }
        }

        &__md-9 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_9} * -1);
                margin-left: calc(#{$gridSpacing_9} * -1);
                width: calc(100% + #{$gridSpacing_9});

                .Grid--item {
                    padding-left: $gridSpacing_9;
                    padding-top: $gridSpacing_9;
                }
            }
        }

        &__md-10 {
            @media only screen and (min-width: $md-min) {
                margin-top: calc(#{$gridSpacing_10} * -1);
                margin-left: calc(#{$gridSpacing_10} * -1);
                width: calc(100% + #{$gridSpacing_10});

                .Grid--item {
                    padding-left: $gridSpacing_10;
                    padding-top: $gridSpacing_10;
                }
            }
        }

        &__lg-1 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_1} * -1);
                margin-left: calc(#{$gridSpacing_1} * -1);
                width: calc(100% + #{$gridSpacing_1});

                .Grid--item {
                    padding-left: #{$gridSpacing_1};
                    padding-top: #{$gridSpacing_1};
                }
            }
        }

        &__lg-2 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_2} * -1);
                margin-left: calc(#{$gridSpacing_2} * -1);
                width: calc(100% + #{$gridSpacing_2});

                .Grid--item {
                    padding-left: #{$gridSpacing_2};
                    padding-top: #{$gridSpacing_2};
                }
            }
        }

        &__lg-3 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_3} * -1);
                margin-left: calc(#{$gridSpacing_3} * -1);
                width: calc(100% + #{$gridSpacing_3});

                .Grid--item {
                    padding-left: #{$gridSpacing_3};
                    padding-top: #{$gridSpacing_3};
                }
            }
        }

        &__lg-4 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_4} * -1);
                margin-left: calc(#{$gridSpacing_4} * -1);
                width: calc(100% + #{$gridSpacing_4});

                .Grid--item {
                    padding-left: #{$gridSpacing_4};
                    padding-top: #{$gridSpacing_4};
                }
            }
        }

        &__lg-5 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_5} * -1);
                margin-left: calc(#{$gridSpacing_5} * -1);
                width: calc(100% + #{$gridSpacing_5});

                .Grid--item {
                    padding-left: $gridSpacing_5;
                    padding-top: $gridSpacing_5;
                }
            }
        }

        &__lg-6 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_6} * -1);
                margin-left: calc(#{$gridSpacing_6} * -1);
                width: calc(100% + #{$gridSpacing_6});

                .Grid--item {
                    padding-left: $gridSpacing_6;
                    padding-top: $gridSpacing_6;
                }
            }
        }

        &__lg-7 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_7} * -1);
                margin-left: calc(#{$gridSpacing_7} * -1);
                width: calc(100% + #{$gridSpacing_7});

                .Grid--item {
                    padding-left: $gridSpacing_7;
                    padding-top: $gridSpacing_7;
                }
            }
        }

        &__lg-8 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_8} * -1);
                margin-left: calc(#{$gridSpacing_8} * -1);
                width: calc(100% + #{$gridSpacing_8});

                .Grid--item {
                    padding-left: $gridSpacing_8;
                    padding-top: $gridSpacing_8;
                }
            }
        }

        &__lg-9 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_9} * -1);
                margin-left: calc(#{$gridSpacing_9} * -1);
                width: calc(100% + #{$gridSpacing_9});

                .Grid--item {
                    padding-left: $gridSpacing_9;
                    padding-top: $gridSpacing_9;
                }
            }
        }

        &__lg-10 {
            @media only screen and (min-width: $lg-min) {
                margin-top: calc(#{$gridSpacing_10} * -1);
                margin-left: calc(#{$gridSpacing_10} * -1);
                width: calc(100% + #{$gridSpacing_10});

                .Grid--item {
                    padding-left: $gridSpacing_10;
                    padding-top: $gridSpacing_10;
                }
            }
        }

        &__xl-1 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_1} * -1);
                margin-left: calc(#{$gridSpacing_1} * -1);
                width: calc(100% + #{$gridSpacing_1});

                .Grid--item {
                    padding-left: #{$gridSpacing_1};
                    padding-top: #{$gridSpacing_1};
                }
            }
        }

        &__xl-2 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_2} * -1);
                margin-left: calc(#{$gridSpacing_2} * -1);
                width: calc(100% + #{$gridSpacing_2});

                .Grid--item {
                    padding-left: #{$gridSpacing_2};
                    padding-top: #{$gridSpacing_2};
                }
            }
        }

        &__xl-3 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_3} * -1);
                margin-left: calc(#{$gridSpacing_3} * -1);
                width: calc(100% + #{$gridSpacing_3});

                .Grid--item {
                    padding-left: #{$gridSpacing_3};
                    padding-top: #{$gridSpacing_3};
                }
            }
        }

        &__xl-4 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_4} * -1);
                margin-left: calc(#{$gridSpacing_4} * -1);
                width: calc(100% + #{$gridSpacing_4});

                .Grid--item {
                    padding-left: #{$gridSpacing_4};
                    padding-top: #{$gridSpacing_4};
                }
            }
        }

        &__xl-5 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_5} * -1);
                margin-left: calc(#{$gridSpacing_5} * -1);
                width: calc(100% + #{$gridSpacing_5});

                .Grid--item {
                    padding-left: $gridSpacing_5;
                    padding-top: $gridSpacing_5;
                }
            }
        }

        &__xl-6 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_6} * -1);
                margin-left: calc(#{$gridSpacing_6} * -1);
                width: calc(100% + #{$gridSpacing_6});

                .Grid--item {
                    padding-left: $gridSpacing_6;
                    padding-top: $gridSpacing_6;
                }
            }
        }

        &__xl-7 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_7} * -1);
                margin-left: calc(#{$gridSpacing_7} * -1);
                width: calc(100% + #{$gridSpacing_7});

                .Grid--item {
                    padding-left: $gridSpacing_7;
                    padding-top: $gridSpacing_7;
                }
            }
        }

        &__xl-8 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_8} * -1);
                margin-left: calc(#{$gridSpacing_8} * -1);
                width: calc(100% + #{$gridSpacing_8});

                .Grid--item {
                    padding-left: $gridSpacing_8;
                    padding-top: $gridSpacing_8;
                }
            }
        }

        &__xl-9 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_9} * -1);
                margin-left: calc(#{$gridSpacing_9} * -1);
                width: calc(100% + #{$gridSpacing_9});

                .Grid--item {
                    padding-left: $gridSpacing_9;
                    padding-top: $gridSpacing_9;
                }
            }
        }

        &__xl-10 {
            @media only screen and (min-width: $xl-min) {
                margin-top: calc(#{$gridSpacing_10} * -1);
                margin-left: calc(#{$gridSpacing_10} * -1);
                width: calc(100% + #{$gridSpacing_10});

                .Grid--item {
                    padding-left: $gridSpacing_10;
                    padding-top: $gridSpacing_10;
                }
            }
        }
    }
    /* --- END SPACING --- */
}

.Grid--item {
    flex-grow: 1;
    /** --- SIZING --- */

    &.col {
        &__xs {
            @media only screen and (min-width: $xs-min) {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }
        }

        &__xs-auto {
            @media only screen and (min-width: $xs-min) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                max-width: none;
                width: auto;
            }
        }

        &__xs-1 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 8.33333333333333%;
                max-width: 8.33333333333333%;
            }
        }

        &__xs-2 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 16.66666666667%;
                max-width: 16.66666666667%;
            }
        }

        &__xs-3 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        &__xs-4 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 33.33333333333%;
                max-width: 33.33333333333%;
            }
        }

        &__xs-5 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 41.666666667%;
                max-width: 41.666666667%;
            }
        }

        &__xs-6 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__xs-7 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 58.333333333%;
                max-width: 58.333333333%;
            }
        }

        &__xs-8 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 66.66666666667%;
                max-width: 66.66666666667%;
            }
        }

        &__xs-9 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 75%;
                max-width: 75%;
            }
        }

        &__xs-10 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 83.3333333333%;
                max-width: 83.3333333333%;
            }
        }

        &__xs-11 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 91.66666666667%;
                max-width: 91.66666666667%;
            }
        }

        &__xs-12 {
            @media only screen and (min-width: $xs-min) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &__sm {
            @media only screen and (min-width: $sm-min) {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }
        }

        &__sm-auto {
            @media only screen and (min-width: $sm-min) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                max-width: none;
                width: auto;
            }
        }

        &__sm-1 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 8.33333333333333%;
                max-width: 8.33333333333333%;
            }
        }

        &__sm-2 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 16.66666666667%;
                max-width: 16.66666666667%;
            }
        }

        &__sm-3 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        &__sm-4 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 33.33333333333%;
                max-width: 33.33333333333%;
            }
        }

        &__sm-5 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 41.666666667%;
                max-width: 41.666666667%;
            }
        }

        &__sm-6 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__sm-7 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 58.333333333%;
                max-width: 58.333333333%;
            }
        }

        &__sm-8 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 66.66666666667%;
                max-width: 66.66666666667%;
            }
        }

        &__sm-9 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 75%;
                max-width: 75%;
            }
        }

        &__sm-10 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 83.3333333333%;
                max-width: 83.3333333333%;
            }
        }

        &__sm-11 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 91.66666666667%;
                max-width: 91.66666666667%;
            }
        }

        &__sm-12 {
            @media only screen and (min-width: $sm-min) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &__md {
            @media only screen and (min-width: $md-min) {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }
        }

        &__md-auto {
            @media only screen and (min-width: $md-min) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                max-width: none;
                width: auto;
            }
        }

        &__md-1 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 8.33333333333333%;
                max-width: 8.33333333333333%;
            }
        }

        &__md-2 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 16.66666666667%;
                max-width: 16.66666666667%;
            }
        }

        &__md-3 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        &__md-4 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 33.33333333333%;
                max-width: 33.33333333333%;
            }
        }

        &__md-5 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 41.666666667%;
                max-width: 41.666666667%;
            }
        }

        &__md-6 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__md-7 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 58.333333333%;
                max-width: 58.333333333%;
            }
        }

        &__md-8 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 66.66666666667%;
                max-width: 66.66666666667%;
            }
        }

        &__md-9 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 75%;
                max-width: 75%;
            }
        }

        &__md-10 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 83.3333333333%;
                max-width: 83.3333333333%;
            }
        }

        &__md-11 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 91.66666666667%;
                max-width: 91.66666666667%;
            }
        }

        &__md-12 {
            @media only screen and (min-width: $md-min) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &__lg {
            @media only screen and (min-width: $lg-min) {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }
        }

        &__lg-auto {
            @media only screen and (min-width: $lg-min) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                max-width: none;
                width: auto;
            }
        }

        &__lg-1 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 8.33333333333333%;
                max-width: 8.33333333333333%;
            }
        }

        &__lg-2 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 16.66666666667%;
                max-width: 16.66666666667%;
            }
        }

        &__lg-3 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        &__lg-4 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 33.33333333333%;
                max-width: 33.33333333333%;
            }
        }

        &__lg-5 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 41.666666667%;
                max-width: 41.666666667%;
            }
        }

        &__lg-6 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__lg-7 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 58.333333333%;
                max-width: 58.333333333%;
            }
        }

        &__lg-8 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 66.66666666667%;
                max-width: 66.66666666667%;
            }
        }

        &__lg-9 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 75%;
                max-width: 75%;
            }
        }

        &__lg-10 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 83.3333333333%;
                max-width: 83.3333333333%;
            }
        }

        &__lg-11 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 91.66666666667%;
                max-width: 91.66666666667%;
            }
        }

        &__lg-12 {
            @media only screen and (min-width: $lg-min) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &__xl {
            @media only screen and (min-width: $xl-min) {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }
        }

        &__xl-auto {
            @media only screen and (min-width: $xl-min) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                max-width: none;
                width: auto;
            }
        }

        &__xl-1 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 8.33333333333333%;
                max-width: 8.33333333333333%;
            }
        }

        &__xl-2 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 16.66666666667%;
                max-width: 16.66666666667%;
            }
        }

        &__xl-3 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        &__xl-4 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 33.33333333333%;
                max-width: 33.33333333333%;
            }
        }

        &__xl-5 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 41.666666667%;
                max-width: 41.666666667%;
            }
        }

        &__xl-6 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__xl-7 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 58.333333333%;
                max-width: 58.333333333%;
            }
        }

        &__xl-8 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 66.66666666667%;
                max-width: 66.66666666667%;
            }
        }

        &__xl-9 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 75%;
                max-width: 75%;
            }
        }

        &__xl-10 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 83.3333333333%;
                max-width: 83.3333333333%;
            }
        }

        &__xl-11 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 91.66666666667%;
                max-width: 91.66666666667%;
            }
        }

        &__xl-12 {
            @media only screen and (min-width: $xl-min) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}
