.sidebar-block {
    width: 21rem;
    @media (max-width: $xs-max) {
        width: 100%;
    } 

    .flag {
        &:before {
            content: '-----------';
            display: inline-block;
            font-size: 1rem;
            font-weight: 500;
            color: $font-color-7;
            margin-right: 0.313rem;
            }
        p {
            display: inline-block;
            font-size: 1rem;
            font-weight: 500;
            color: $font-color-7;
            margin-bottom: .5rem;
        
        }
    }

    .container {
        background-color: $font-color-2;

        .page-card {
            padding: 1rem;
            .flag {
                display: none;
            }
        }
    }
}