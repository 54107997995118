﻿:where(ul[role=list],ol[role=list],ol[role=menu],ul[role=menu]) {
    list-style: none;
    margin: 0;
    padding: 0
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    font-family: $font-family-2;
    font-size: 1rem;
    line-height:1.5;
}

p {
    font-family: $font-family-2;
}

span {
    font-family: $font-family-1;
}

.sticky-container-top {
    display: block;
    width: 100%;
    position: fixed;
    top:0;
    z-index: 99;
    background-color: white;
}