﻿.carousel {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: baseline;

  .swiper-slide {
    display: flex;
    text-align: center;
    font-size: 18px;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-block {
      padding: 0;
    }
  }

  .swiper-pagination {
    position: static;
    margin-top: 3.9375rem;
    margin-bottom: 2rem;
  }

  .swiper-button-prev i,
  .swiper-button-next i {
    font-size: 2.5rem;
    color: $font-color-3;
  }

  .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    opacity: 0.5;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-pagination:not([data-color-class]) {
    // Default pagination bullet styles used when no color class is specified
    .swiper-pagination-bullet {
      background-color: #717782;
      opacity: 1;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #121e33;
      opacity: 1;
    }
  }

  // DESKTOP
  @media (min-width: ($md-max + 1)) {
    .swiper-button-prev,
    .swiper-button-next {
      display: unset;
    }
  }

  .swiper-button-lock {
    display: none;
  }

  // MOBILE
  @media (max-width: $md-max) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
