.wizard-forms {
    .wizard-step-item {
        display: none;

        &.active {
            display: block;
        }
    }
}

.trial-form {
    .trial-step-form {
        display: none;

        &.active {
            display: block;
        }

        .marketo-form {}
    }
}


/*
.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #f1f1f1;
    border: solid white 10px;
    border-radius: 50%;
}
*/