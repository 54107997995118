.article-hero-block {
  &__article-info {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    align-items: center;
    margin-top: 1rem;

    @media (min-width: $md-min) {
      font-size: 1.5rem;
    }

    .release-date {
      font-weight: 500;

      &::after {
        margin-left: 1rem;
        margin-right: 1rem;
        content: '|';
      }
    }

    .time-to-read {
      .fa-clock {
        margin-right: 0.5rem;
      }
    }
  }

  &__authors {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    @media (max-width: $sm-max) {
      font-size: 1.25rem;
    }

    &-label {
      margin-right: 0.25rem;
    }

    &-list {
      display: inline-block;

      a {
        color: $font-color-9;
        text-decoration: underline;
      }
    }

    &-author::after {
      content: ', ';
      text-decoration: none;
    }

    &-author:last-child::after {
      content: '';
    }
  }

  &__tag-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    @media (min-width: $md-min) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    a {
      padding: 1rem 1.25rem;
      background-color: white;
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
