.sidebar-container-block {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media only screen and (min-width: $lg-min) {
    padding: 2rem;
    gap: 3rem;
  }

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
}

.sidebar-item-block {
  &__heading {
    font-size: 1rem;
  }
  &__content {
    display: flex;
    &.sidebar-cta-flex-column {
      flex-direction: column;
      flex-wrap: nowrap;
      .call-to-action {
        a {
          justify-content: flex-start;
        }
      }
    }
    &.sidebar-cta-flex-row {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  &__separator-horizontal {
    width: 100%;
    margin-top: 2rem;
    border: none;
    border-top: 1.25px solid $font-color-2;

    @media only screen and (min-width: $lg-min) {
      margin-top: 3rem;
    }
  }
}
