.content-tile {
  width: 100%;
  background-color: $font-color-16;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;

    .content-tile__title {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  &__eyebrow-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    height: 1.5rem;
    color: $font-color-9;
  }

  &__eyebrow {
    display: inline-block;
    width: 2.5rem;
    height: 2px;
    font-weight: 500;
    background-color: $font-color-6;
    margin-right: 0.313rem;
  }

  &__image {
    figure,
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__details-wrapper {
    display: flex;
    padding: 0 20px 20px;
  }

  &__tags {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    margin-top: 1.5rem;
  }

  &__description,
  &__description > * {
    font-family: $font-family-2;
    color: $font-color-3;
    margin-top: 1rem;
  }
}
