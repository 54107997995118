.tabpanelcontainer-with-sidebar {
  width: 100%;
  &__tabcordion-with-sidebar {
    display: flex;

    @media only screen and (max-width: $md-max) {
      display: none;
    }
  }

  &__tabcordion {
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
  }

  &__sidebar {
    flex-basis: 33.33333333333333%;
    max-width: 33.33333333333333%;
    margin-left: 2rem;
    & .sidebar-item-block__content {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .call-to-action {
        .cta-text {
          font-size: 1rem;
        }
      }
    }
  }

  .accordion__item_body-content {
    padding: 0;
  }

  .resp-tabs-list {
    justify-content: flex-start;
    border-bottom: 0.3125rem solid $font-color-2;
    margin-bottom: 2rem;
  }

  .resp-tab-content {
    background: $font-color-16;
  }

  &__accordion {
    display: none;
    margin: 0;

    @media only screen and (max-width: $md-max) {
      display: block;
    }

    .accordion__item_body {
      background: $font-color-16;
    }
  }
}
