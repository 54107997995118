.tile-block {
  height: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    @media (min-width: $lg-min) {
      padding: 1.5rem;
    }
  }
  &__heading {
    .heading-title {
      font-size: 1.25rem;
      font-weight: normal;
      margin-bottom: 1.5rem;
      @media (min-width: $lg-min) {
        font-size: 1.5rem;
      }
    }
  }
  &__description {
    & > * {
      font-size: 1rem;
      font-weight: normal;
    }
  }
  a {
    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
      transition: box-shadow 0.3s ease-in-out;
      .heading-title {
        text-decoration: underline;
        transition: text-decoration 0.3s ease-in-out;
      }
    }
  }
}
