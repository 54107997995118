.promo-block {
  max-width: 81rem;
  margin: 0 auto;
  @media screen and (min-width: $lg-min) {
    display: flex;
    flex-direction: row;
    &--image-right {
      flex-direction: row-reverse;
    }
  }

  &__image-wrapper {
    flex: 1;
    flex-shrink: 0;
    flex-basis: 50%;

    img {
      object-fit: cover;
      width: 100%;
      display: block;
    }
  }
  & .heading-wrapper-h6 {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__content-wrapper {
    flex: 1;
    flex-basis: 50%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $lg-min) {
      padding: 0 2.5rem 1.5rem;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__certified {
    margin-bottom: 1rem;
  }

  &__cta {
    margin-top: 2.5rem;

    @media screen and (min-width: $lg-min) {
      margin-top: 4rem;
    }
  }
}

.promo-heading {
    display: flex;
    align-items: center;
    gap: 3px;
    text-transform: uppercase;
    flex-flow: row-reverse;
    margin-bottom: 12px;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.37px;
    letter-spacing: 0.02em;
    font-family: $font-family-2;
}

.promo-heading .heading-title {
    font-weight: 400;
}

.promo-heading .heading-title:nth-child(2) {
    font-weight: 800;
}

.promo-block__heading .heading-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;    
    font-family: $font-family-2;
    margin-bottom: 15px;
}

.promo-block .promo-block__description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    font-family: $font-family-1;
    margin-bottom: 13px;
    position: relative;
}

.promo-block .promo-block__description p b {
    font-weight: 600;
}

.promo-block .promo-block__description p:has(> img) {
    padding-left: 25px;
}

.promo-block .promo-block__description p:has(> img) img {
    margin-top: 4px;
    position: absolute;
    left: 0;
}

@media (max-width:767px) {
    .promo-block__heading .heading-title {
        font-size: 22px;
        line-height: 27.26px;
        margin-bottom: 12px;
    }

    .promo-block .promo-block__description p {
        font-size: 14.5px;
        line-height: 19px;
        margin-bottom: 11px;
    }
}