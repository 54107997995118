.biography-cardlist-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 0 6.25rem 0;
    padding: 0;

    @media (max-width: $xs-max) {
      display: flex;
      flex-direction: column;
    }

    &__card {
      list-style: none;
      width: 23.125rem;
      max-width: 100%;
      margin-bottom: 3rem;

      @media (max-width: $xs-max) {
        width: 100%;
        margin-bottom: 3.4375rem;
      }
    }
  }
}
