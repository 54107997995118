// 1. Import Plugin styles
@import 'swiper/swiper-bundle.css';
@import 'typeahead';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';

// 1. Local Dependencies (Ordered)
/* 1. */ @import './base/_colors.scss';
/* 2. */ @import './base/_typography.scss';
/* 3. */ @import './base/_fonts.scss';
/* 4. */ @import './base/_breakpoints.scss';
/* 5. */ @import './base/_bootstrap-grid.scss';
/* 6. */ @import './base/_grid.scss';
/* 7. */ @import './base/_opacity.scss';
/* 8. */ @import './base/_padding.scss';
/* 9. */ @import './base/_margins.scss';
/* 10. */ @import './base/_animations.scss';
/* 11. */ @import './base/_body.scss';
/* 12. */ @import './base/_headings.scss';
/* 13. */ @import './base/_hyperlinks.scss';

// 6. Import Plugin Overrides
@import './plugins/_swiper-overwrites.scss';

// Import components
@import './components/accordion.scss';
@import './components/agenda.scss';
@import './components/article-hero.scss';
@import './components/banner.scss';
@import './components/biography-card.scss';
@import './components/biography-cardlist.scss';
@import './components/biography-detail.scss';
@import './components/breadcrumbs.scss';
@import './components/cta.scss';
@import './components/cards.scss';
@import './components/carousel.scss';
@import './components/content-tile.scss';
@import './components/dynamic-content.scss';
@import './components/event-card.scss';
@import './components/event-schedule-form.scss';
@import './components/footer.scss';
@import './components/gallerycarousel.scss';
@import './components/hero.scss';
@import './components/herocarousel.scss';
@import './components/image.scss';
@import './components/main-navigation.scss';
@import './components/modal.scss';
@import './components/product-card.scss';
@import './components/product-feed.scss';
@import './components/promo.scss';
@import './components/quote.scss';
@import './components/search-bar.scss';
@import './components/sidebar.scss';
@import './components/secondary-nav.scss';
@import './components/section-block.scss';
@import './components/sidebar-container.scss';
@import './components/tabpanelcontainer.scss';
@import './components/tabpanelcontainer-with-sidebar.scss';
@import './components/tags.scss';
@import './components/table.scss';
@import './components/tile-block.scss';
@import './components/video.scss';
@import './components/webform.scss';
@import './components/list-cta.scss';
@import './components/marketo-form.scss';
@import './components/text-block.scss';
@import './components/utility-navigation.scss';
@import './components/wizard-forms.scss';
@import './components/trial-forms.scss';
@import './components/spinner.scss';
@import './components/trust-pilot.scss';
@import '../../../../Presentation/scss/components/timer.scss';


// Import pages
@import './pages/search-results.scss';
@import './pages/blog.scss';
@import './pages/resource-details.scss';
