// Note: this rule below is a temporary fix to make social icons display
.footer .footer-content-wrapper .Grid--item .section-block__container {
  &:has(.list-cta) {
    padding-bottom: 0;
  }
  .section-block .Grid--container {
    &:not(:has(.list-cta)) {
      justify-content: space-around;
      @media screen and (min-width: $md-min) {
        justify-content: flex-start;
      }
    }
    &:has(.list-cta) {
      display: flex;
      flex-direction: column;
    }
    .list-cta.mobile {
      width: 100%;
    }
    .call-to-action:has(.call-to-action-image) {
      width: auto;
    }

    @media screen and (min-width: $md-min) {
      justify-content: flex-start;
    }
    @media screen and (max-width: $md-max) {
      .Grid--item:has(.list-cta) ~ .call-to-action,
      .list-cta ~ .call-to-action {
        padding-bottom: 1rem;
      }
    }
    .Grid--item:has(.list-cta) ~ .call-to-action a,
    .list-cta ~ .call-to-action a {
      justify-content: flex-start;
    }
    .Grid--item:has(.list-cta) ~ .call-to-action .cta-text,
    .Grid--item:has(.list-cta) ~ .call-to-action .call-to-action-iconbox,
    .list-cta ~ .call-to-action .cta-text,
    .list-cta ~ .call-to-action .call-to-action-iconbox {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
}

.footer {
  position: relative;
  background-color: $background-color-3;
  padding: 0;

  & .footer-content-wrapper {
    & .call-to-action {
      a {
        padding-block-start: 0;
        background-color: $transparent;
      }
    }
    & > .Grid--container {
      padding: 3rem 1.5rem;
      @media screen and (min-width: $md-min) {
        padding: 5rem 2.5rem 3rem;
      }
      @media screen and (min-width: $xl-min) {
        padding: 7rem 10rem 3rem;
      }
    }
    & .list-cta.desktop {
      & .heading-title {
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }
    }
    & .list-cta.mobile {
      & .list-cta-accordion-content {
        & .call-to-action {
          a {
            justify-content: flex-start;
            padding-top: 0;
          }
        }
      }
      & .heading-title {
        font-size: 1.125rem;
      }
    }
    & .list-cta {
      &:not(.container) {
        flex-direction: column;
      }
    }
    & .list-cta.container {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding-inline: 0 2rem;
    }
  }
  & .footer-bottom {
    padding: 0 1.5rem;
    .Grid--container {
      padding: 0 0 4.375rem;
      width: 100%;
      @media screen and (min-width: $md-min) {
        padding: 0 1.5rem 1.5rem;
      }
      @media screen and (min-width: $xl-min) {
        padding: 0 8rem 1.5rem;
      }
      & .links {
        & .call-to-action {
          position: relative;
          padding-inline: 0.5rem;
          &:before {
            position: absolute;
            content: '';
            height: 70%;
            top: 50%;
            left: 0;
            translate: 0 -50%;
            background-color: $background-color-16;
            width: 1.5px;
          }
          &:nth-of-type(1) {
            &:before {
              background-color: transparent;
            }
          }
          a {
            padding: 0;
            & .cta-text {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
  & .heading,
  & .section-block {
    .heading-title {
      font-weight: 700;
      color: white;
      font-size: 1.125rem;
    }
  }
  & .section-block__container {
    padding: 0;
  }

  & .section-block {
    padding: 0;
    & .section-block__title {
      text-align: left;
      .heading-title {
        font-weight: 700;
        color: white;
        font-size: 1.125rem;
        text-align: left;
      }
    }
    @media only screen and (min-width: $lg-min) {
      padding: 0;
    }
  }

  .footer-links {
    list-style-type: none;
    padding-left: 0;

    li {
      list-style: none;
      padding-bottom: 1.25rem;

      &::marker {
        list-style: none;
      }

      a {
        color: white;
        font-size: 1rem !important;
        font-weight: 400;
      }

      i {
        padding-left: 0.5rem;
      }
    }
  }

  .email-field {
    .heading {
      h2 {
        font-size: 1.25rem;
        color: white !important;
      }
    }

    input {
      width: 18rem;
      height: 3.625rem;
      font-size: 1rem;
      font-family: $font-family-2;
      color: $font-color-9;
      padding: 1.3125rem 1.5rem;

      @media (max-width: $sm-max) {
        width: auto;
      }
    }

    button {
      height: 3.625rem;
      width: 3.625rem;
      background: $transparent;
    }
  }

  a.fa-brands,
  a.fa {
    color: white !important;
    margin: 1.5rem 0;
    &:hover {
      text-decoration: none;
      color: $transparent;
    }
  }

  .social-icons {
    margin-top: 3.5rem;

    .icons {
      list-style-type: none;
      padding-left: 0;

      li {
        display: inline-block;
        list-style: none;
        margin-right: 2rem;

        &:last-child {
          @media (max-width: $sm-max) {
            margin-right: 0;
          }
        }

        @media (max-width: $sm-max) {
          margin-bottom: 2rem;
        }

        &::marker {
          list-style: none;
        }

        i {
          color: white;
        }
      }
    }
  }

  .footer-bottom {
    @media (max-width: $sm-max) {
      margin: 0;
    }

    .hairline {
      display: block;
      width: 100%;
      border-top: 1px solid $font-color-10;
      margin-bottom: 2.5rem;
      @media screen and (min-width: $lg-min) {
        margin-bottom: 1.5rem;
      }
    }

    .copyright {
      p {
        font-size: 0.75rem;
        margin: 0 0 1rem;
        text-align: center;
        font-weight: 500;
        color: $font-color-16;
        @media (min-width: $lg-min) {
          margin: 0;
          text-align: left;
        }
        i {
          font-size: 0.65rem;
        }
      }
    }

    .footer-bottom-links {
      text-align: center;
      display: flex;
      justify-content: center;
      @media (min-width: $lg-min) {
        text-align: right;
        justify-content: flex-end;
      }

      .call-to-action {
        width: fit-content;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          height: 60%;
          width: 1px;          
          right: 0;
          top: 50%;
          translate: 0 -50%;
        }
        a {
          padding: 0 0.5rem;
          .cta-text {
            font-size: 0.75rem;
            font-weight: 500;
            color: white;
          }
        }
      }
      .call-to-action {
        &:last-child {
          &:after {
            background-color: transparent;
          }
        }
      }
    }
  }
  // Mobile accordion

  button.collapsible {
    background: none;
    border: none;
    padding: 0;

    @media (max-width: $xs-max) {
      text-align: left;
      width: 100%;
      position: relative;

      &:after {
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        position: absolute;
        top: 0.125rem;
        right: 0;
        margin-left: 5px;
      }

      &.active {
        &:after {
          content: '\2796' !important; /* Unicode character for "minus" sign (-) */
        }
      }
    }
  }

  .expand {
    display: block;

    @media (max-width: $xs-max) {
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      max-height: 0;
    }
  }

  .hairline-mobile {
    display: none;

    @media (max-width: $sm-max) {
      display: block;
      width: 100%;
      border-top: 1px solid $font-color-10;
      margin-bottom: 1.5rem;
    }
  }
}
