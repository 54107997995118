
.utility-navigation {
  $utilbreakpoint: 64rem;
  box-sizing: border-box;
  padding: 1.875rem;
  position: relative;
  max-height: 2rem;
  
  & > .call-to-action-content{
    width:100%;
  }

  header & {
    display: flex;
    padding: 1.875rem 1.875rem 4rem;
    position: relative;
    height: 100%;
    flex-direction: column;
    @media (min-width: $utilbreakpoint) {
      padding: 0 2.5rem 0 0;
      background: #fff;
      header & {
        flex-direction: row;
        height: auto;
      }
    }
  }
  & > .container {
    width: 100%;
    display: flex;
    padding: 0;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    > .call-to-action:first-of-type {
      padding: 0;
      margin: 0 auto;
      position: relative;
      font-size: 0.875rem;
      text-transform: capitalize;
      & .fa {
        font-size: 0.875rem;
      }
      & .call-to-action-content {
        & .cta-text {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
      order: 1;
    }

    @media (min-width: $utilbreakpoint) {
      flex-direction: row;
      height: auto;
      flex-wrap: nowrap;
      > .call-to-action:first-of-type {
        font-size: 0.875rem;
        padding: 4px 1rem;
        margin: 0;
        display: flex;
        align-items: center;
        width: fit-content;
      }
    }

    .button-wrapper {
      display: flex;
      order: 3;
      width: 100%;
      background-color: $font-color-2;
      bottom: 0;
      gap: 0;
      z-index: 1;
      & .call-to-action {
        padding-block: 4px;
        flex: 1;
        a {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          flex: 1;
          margin: 0;
          padding: 1rem min(0, 0.5rem);
          & .call-to-action-content {
            & .cta-text {
              text-transform: capitalize;
              font-size: 0.75rem;
            }
          }
        }
        &:nth-child(2) {
          position: relative;
          &::before,
          &::after {
            position: absolute;
            top: 0;
            display: block;
            width: 0.125rem;
            height: 3.5rem;
            margin: 0;
            background: white;
            content: '';
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
      }

      .resp-tabs-container.active & {
        position: fixed;
      }

      @media (min-width: $utilbreakpoint) {
        // display over other stuff in mobile menu
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        flex-basis: auto;
        gap: 1.5rem;
        background-color: $font-color-16;
        bottom: unset;
        width: auto;
        .call-to-action {
          flex-basis: auto;
        }
      }
    }

    .right-links {
      display: flex;
      width: calc(100% - 0.25rem);
      margin: 0 0 1.875rem 0;
      padding: 0;
      order: 2;

      .call-to-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        order: 2;
      }
      @media (min-width: $utilbreakpoint) {
        position: relative;
        margin: 0 0 0 2.5rem;
        padding: 0 0 0 2.5rem;
        order: 3;
        width: auto;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          translate: 0 -50%;
          width: 0.125rem;
          height: 2.5rem;
          margin-top: -1.25rem;
          background: white;
          content: '';
        }
        .call-to-action {
          position: relative;
          color: $font-color-8;
          border: none;
          text-transform: uppercase;

          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}
