.breadcrumbs-block {
  margin-top: 1.5rem;

  &__list {
    display: flex;
    margin: 0 0 1.5rem 0;
    padding: 0;
  }

  &__item {
    position: relative;
    list-style: none;
    font-weight: normal;
    margin-right: 1rem;

    @media (max-width: $xs-max) {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }

    &::after {
      position: absolute;
      bottom: 0;
      right: -0.5rem;
      content: '|';
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__item-link {
    display: flex;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0;
    min-width: auto;
    @media (max-width: $xs-max) {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
}
