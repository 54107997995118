.agenda-item-block {
  .Grid--container {
    background: $background-color-2;
    padding: 1rem;
    @media (min-width: $lg-min) {
      padding: 2rem;
    }
  }

  table.table {
    display: table;
    width: 100%;
    margin: 0;
    border-top: 1px solid #dad9d7;

    td {
      display: block;
      padding: 0rem;
      @media (min-width: $lg-min) {
        padding: 0.75rem;
        display: table-cell;
      }
      &.agenda-item {
        vertical-align: middle;
        text-align: left;
        p {
          font-size: 1.25rem;
          font-weight: normal;
          color: $background-color-3;
        }
        @media screen and (min-width: $lg-min) {
          flex-basis: 50%;
          p {
            @media screen and (min-width: $lg-min) {
              font-size: 1.5rem;
            }
          }
        }
      }
      &.time {
        vertical-align: middle;
        margin-bottom: 0.5rem;
        p {
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          color: $font-color-3;
        }
        @media (min-width: $lg-min) {
          flex-basis: 50%;
          margin-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $background-color-3;
        display: flex;
        flex-direction: column;
        padding: 0.75rem 0;
        text-align: left;

        @media (min-width: $md-min) {
          flex-direction: row;
          padding: 0;
          border-bottom: 1px solid $border-color-3;
          align-items: center;
        }
      }
    }
  }
}
