.biography-card {
  img {
    width: 26.25rem;
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
  .call-to-action {
    button {
      justify-content: flex-start;
    }
  }
}
