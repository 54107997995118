﻿:root {
    --swiper-theme-color: #{$border-color-2};
    // needs to be px since swiper doesn't accept rem
    --custom-swiper-space-between: 0;
    --swiper-navigation-size: 2.25rem; // 36px
    --swiper-pagination-bullet-width: #{$font-medium};
    --swiper-pagination-bullet-height: #{$font-medium};
    --swiper-pagination-bullet-inactive-color: #{$font-color-5};
    --swiper-pagination-bottom: calc(calc(#{$padding-xl} / 2) - var(--swiper-pagination-bullet-width));
    --swiper-pagination-bullet-horizontal-gap: 0.375rem; // 6px * 2 = 12px
}

.swiper {
    padding-inline: var(--custom-swiper-space-between);
    padding-block: 0 $padding-xs;

    &:not(.swiper-initialized) {
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination {
            display: none;
        }

        padding-block: 0;
    }
}

.swiper-wrapper {
    margin: 0;
    padding: 0;
}

.swiper-slide {
    width: 100%;
    padding-right: var(--custom-swiper-space-between);
}

.swiper-button-prev,
.swiper-button-next {
    display: none;
    background-color: transparent;
    border: none;

    &::after {
        font-family: 'Font Awesome 6 Free';
        font-weight: 700;
    }
}

.swiper-button-prev {
    &::after {
        content: '\f104';
    }
}

.swiper-button-next {
    &::after {
        content: '\f105';
    }
}

.swiper-pagination {
    display: block;
}

@include tablet-up {
    :root {
        --custom-swiper-space-between: #{$padding-xl}; // 64px
    }

    .swiper {
        padding-block: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
    }
    /*.swiper-pagination {
        display: none;
    }*/
}
