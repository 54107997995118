.event-schedule-form {
  width: 100%;
  padding: 2rem;
  max-width: $content__max-width;
  margin: auto;
  font-family: $font-family-2;
  position: relative;
  background-color: var(--scoped-background-color);

  .heading-title {
    font-weight: normal;
    margin-bottom: 1.5rem;
    color: inherit;
  }
  
  hr {
    margin-bottom: 1.5rem;
  }
  select {
    height: 2.625rem;
    color: $font-color-3;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0.9375rem;
    padding: 0.5625rem 0.8125rem;
    max-width: 100%;
  }
  &__btn-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: flex-start;
  }
  &__tz-selection-container {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    z-index: 80;
    background-color: var(--scoped-background-color);
    padding: 1.5rem;
    width: fit-content;
    max-width: calc(100% - 1.35rem);
    border: solid 1px $font-color-16;
    border-radius: 5px;
    display: none;
  }
  &__tz-selection-container.active {
    display: block;
  }
  &__display-selected-tz {
    margin-bottom: 1rem;
    .event-schedule-form__change-tz-link {
      text-decoration: underline;
    }
    .event-schedule-form__change-tz-link:hover {
      cursor: pointer;
    }
  }
  &__available-times {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  button,
  input[type='submit'] {
    padding: 1rem;
    background: $font-color-5;
    color: $font-color-3;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    background-image: none;
    border-radius: 0;
  }
  button:hover,
  input[type='submit']:hover {
    background: $font-color-4;
    color: $font-color-16;
  }
}
form .event-schedule-form__btn-container .event-schedule-form__submit-btn {
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 19.36px;
    border: 1px solid #00202E !important;
    box-shadow: 4px 4px 0px 0px #00202E;
    border-radius: 8px !important;
    height: 44px;
    padding: 8px 20px !important;
    width: auto !important;
    text-transform: none;
    letter-spacing: 0.5px !important;
}

form .event-schedule-form__btn-container .event-schedule-form__submit-btn:hover {
    box-shadow: none;
}